import React,{Fragment} from 'react';
// import { Field, reduxForm } from 'redux-form';
import LoadingSpinner from '../../../utils/LoadingSpinner';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
// import {CardElement, injectStripe} from 'react-stripe-elements';
// import StripeCheckout from "react-stripe-checkout";
// const initialValuesSchema = {
//   email: '',
//   fullname:'',
//   phone_no: '',
//   address:'',
//   password: '',
//   confirmPassword: '',
//   security_question_answer:'',
//   notes:'',

// }

const AddressDetailForm = (props) => {
  const { initialValues,isOrderSavingLoader, inputAddressChangedHandler, query, userData,delivery_type, auth_type } = props
  // const { onSubmitForm,onToken,initialValues,isOrderSavingLoader, handleSubmit, inputAddressChangedHandler, query, userData,delivery_type } = props
  // const { isOrderSavingLoader, handleSubmit, pristine, reset, submitting, inputAddressChangedHandler, query, userData } = props
  let AddressDetailSchema = {};
  let objAddressDetailSchema = {
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    // fullname: Yup.string().required('full Name is Required'),
    phone_no: Yup.string()
        .min(10, 'Phone No must be atleast of 10 numbers')
        .required('Phone No is required'),
    // email: Yup.string()
    //     .email('Please provide a valid email')
    //     .required('Email is required'),
    
  }
  if(auth_type !== 'guest'){
    objAddressDetailSchema.email = Yup.string().email('Please provide a valid email').required('Email is required');
    if(userData.isAuth){
      if(userData.security_question_answer === undefined || userData.security_question_answer === 'undefined' || userData.security_question_answer === ''){
        objAddressDetailSchema.security_question_answer = Yup.string().min(3, 'Answer must be atleast of 3 characters').required('Answer is Required');
      }
      AddressDetailSchema = Yup.object().shape(objAddressDetailSchema);
    }else{
      objAddressDetailSchema.password = Yup.string().min(8, 'Password Too Short!').max(50, 'Password Too Long!').required('Password is Required');
      objAddressDetailSchema.confirmPassword =Yup.string().min(8, 'Password Too Short!').max(50, 'Password Too Long!').oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required');
      objAddressDetailSchema.security_question_answer = Yup.string().min(3, 'Answer must be atleast of 3 characters').required('Answer is Required');
      AddressDetailSchema = Yup.object().shape(objAddressDetailSchema);
    }
  }else{
    AddressDetailSchema = Yup.object().shape(objAddressDetailSchema);
  }
// console.log('AddressDetailSchema')
// console.log('AddressDetailSchema')
// console.log(AddressDetailSchema)
console.log('initialValues')
console.log('initialValues')
console.log(initialValues)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddressDetailSchema}
      onSubmit={props.onSubmitForm}
      render={({ errors, status, touched }) => (
          <Form autoComplete="new-password" >
              <div className="row bg-grey border-round product-bill p-0 d-none">
                <div className="col-12">
                    <Field name="drpCity" component="select" className="form-control form-control2">
                        <option  disabled>Select City</option>
                        <option value="Windsor Ontario" >Windsor Ontario</option>
                    </Field>
                </div>
              </div>
              {/* <div className="row bg-grey border-round product-bill p-0">
                  <div className="col-12">
                      <Field placeholder="Full Name" name="fullname" type="text" className={'form-control form-control2' + (errors.fullname && touched.fullname ? ' is-invalid' : '')} />
                      <ErrorMessage name="fullname" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                  </div>
              </div> */}
              <div className="row bg-grey border-round product-bill p-0">
                  <div className="col-12">
                      <Field placeholder="First Name" autoComplete="off" name="firstname" type="text" className={'form-control form-control2' + (errors.firstname && touched.firstname ? ' is-invalid' : '')} />
                      <ErrorMessage name="firstname" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                  </div>
              </div>
              <div className="row bg-grey border-round product-bill p-0">
                  <div className="col-12">
                      <Field placeholder="Last Name" autoComplete="off" name="lastname" type="text" className={'form-control form-control2' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} />
                      <ErrorMessage name="lastname" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                  </div>
              </div>
              {
                delivery_type === 'delivery' ?
                  <Fragment>
                    <div className="row bg-grey border-round product-bill p-0">
                      <div className="col-12">
                        <input placeholder="Address" type="text" className="form-control form-control2"  id="autocomplete" value={query} autoComplete="off" onChange={(event)=>inputAddressChangedHandler(event)} />
                        {
                          props.isCalculatingDeliveryFee === false ?
                          <Fragment>
                          {
                            props.delivery_amount !== 0 ?
                            <p className="txtDeliveryFeeMention">Delivery fee: ${props.delivery_amount} </p>
                            : null
                          }
                          </Fragment>
                          : <LoadingSpinner subClass={'mt10 mb10'} />
                        }
                      </div>
                    </div>
                    <div className="row bg-grey border-round product-bill p-0">
                        <div className="col-12">
                            <Field placeholder="Appartment/Unit Number" autoComplete="off" name="appartment_unit_number" type="text" className={'form-control form-control2' + (errors.appartment_unit_number && touched.appartment_unit_number ? ' is-invalid' : '')} />
                            <ErrorMessage name="appartment_unit_number" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                        </div>
                    </div>
                  </Fragment>
                :null
              }
              {
                auth_type !== 'guest' ?
                  <div className="row bg-grey border-round product-bill p-0">
                    <div className="col-12">
                      <Field placeholder="Email" autoComplete="off" disabled={userData.isAuth ? true : false} name="email" type="text" className={'form-control form-control2' + (errors.email && touched.email ? ' is-invalid' : '')} />
                      <ErrorMessage name="email" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                    </div>
                  </div>
                : null
              }
              <div className="row bg-grey border-round product-bill p-0">
                  <div className="col-12">
                      <Field placeholder="Phone No" autoComplete="off" name="phone_no" type="number" className={'form-control form-control2' + (errors.phone_no && touched.phone_no ? ' is-invalid' : '')} />
                      <ErrorMessage name="phone_no" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                  </div>
              </div>
              {
                auth_type !== 'guest' ?
                  <div className="row bg-grey border-round product-bill p-0">
                    <div className="col-12">
                      <Field placeholder="Notes" autoComplete="off" name="notes" type="text" className={'form-control form-control2' + (errors.notes && touched.notes ? ' is-invalid' : '')} />
                      <ErrorMessage name="notes" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                    </div>
                  </div>
                  : null
              }
              {
                auth_type !== 'guest' && userData.isAuth === false ?
                  <Fragment>
                    <div className="row bg-grey border-round product-bill p-0 mb-0">
                        <div className="col-12">
                            <Field placeholder="Password" autoComplete="off" name="password" type="password" className={'form-control form-control2' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                        </div>
                    </div>
                    <div className="row border-round product-bill pl-1 text-right pb-0 pt-0 mt-0">
                        <div className="">
                            <Link to={`/forgot-password/true/${delivery_type}`}><small>Fogot Password?</small></Link>
                        </div>
                    </div>
                    <div className="row bg-grey border-round product-bill p-0">
                        <div className="col-12">
                            <Field placeholder="Confirm password" autoComplete="off" name="confirmPassword" type="password" className={'form-control form-control2' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                        </div>
                    </div>
                  </Fragment>
                :null
              }
              {
                auth_type !== 'guest' ?
                  <Fragment>
                    {
                      (userData.isAuth &&(userData.security_question_answer === undefined || userData.security_question_answer === 'undefined' || userData.security_question_answer === '')) || userData.isAuth === false ?
                        <div className="row bg-grey border-round product-bill p-0">
                            <div className="col-12">{auth_type}
                                <Field placeholder="Mother's maiden name" autoComplete="off" name="security_question_answer" type="text" className={'form-control form-control2' + (errors.security_question_answer && touched.security_question_answer ? ' is-invalid' : '')} />
                                <ErrorMessage name="security_question_answer" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                            </div>
                        </div>
                      :null
                    }     
                  </Fragment>
                :null
              }   
              
              
              {
                isOrderSavingLoader === false ?
                  <div className="row product-action-btn mb-2 px-2">
                      <div className="col-12">
                          <div className="my-cart-btn">
                              <button type="submit" className="btn btn-primary w-100">Submit</button>
                          </div>
                      </div>
                  </div>
                : <LoadingSpinner subClass={'mt10 mb10'} />
              }
          </Form>
      )}
  />
    
  )
}



export default AddressDetailForm;

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { registerUser } from '../../../actions/user_actions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { showMsg } from '../../utils/general';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const initialValuesSchema = {
    firstname:'',
    lastname:'',
    phone_no: '',
    email: '',
    password: '',
    confirmPassword: '',
    security_question_answer:'',
}
const RegisterSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is eequired'),
    lastname: Yup.string().required('Last name is eequired'),
    password: Yup.string()
        .min(8, 'Password Too Short!')
        .max(50, 'Password Too Long!')
        .required('Password is Required'),
    phone_no: Yup.string()
        .min(10, 'Phone No must be atleast of 10 numbers')
        .required('Phone No is Required'),
    confirmPassword: Yup.string()
        .min(8, 'Password Too Short!')
        .max(50, 'Password Too Long!')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    email: Yup.string()
        .email('Please provide a valid email')
        .required('Email is Required'),
    security_question_answer: Yup.string()
        .min(3, 'Answer must be atleast of 3 characters')
        .required('Answer is Required'),
        
        
});
class Register extends Component {
    _isMounted = false;
    state = {
        isloading: false,
        formError: false,
        formSuccess:'',
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    onSubmitForm = async (fields) => {
        console.log('fields in submit')
        console.log(fields)
        if (this._isMounted) {
            this.setState({
                formError: false,
                isloading:true,
            })
        }
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4))
        this.props.dispatch(registerUser(fields)).then(response =>{
            // console.log('response.payload');
            // console.log(response.payload);
            if(response.payload.success){
                showMsg("Congratulations !! You have been registered successfully login to continue.",'success');
                this.props.history.push('/login')
            }else{
                if(response.payload.msg){
                    showMsg(response.payload.msg,'error');
                }
                this.setState({
                    formError: true,
                    isloading:false,
                })
            }
        });
        
    }

    


    
    
    render() {
        // console.log('this.state.isloading')
        // console.log(this.state.isloading)
        return (
                <Fragment>
                    <header className="container inner-header">
                        <div className="row">
                            <div className="col-12">
                                <h4>Register</h4>
                            </div>
                        </div>
                    </header>
                    <div className="inner-body addressdetails-main">
                        <div className="container my-2">
                            <Formik
                                initialValues={initialValuesSchema}
                                validationSchema={RegisterSchema}
                                onSubmit={this.onSubmitForm}
                                render={({ errors, status, touched }) => (
                                    <Form>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="First Name" name="firstname" type="text" className={'form-control form-control2' + (errors.firstname && touched.firstname ? ' is-invalid' : '')} />
                                                <ErrorMessage name="firstname" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Last Name" name="lastname" type="text" className={'form-control form-control2' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} />
                                                <ErrorMessage name="lastname" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            {/* <label htmlFor="email">Email</label> */}
                                            <div className="col-12">
                                                <Field placeholder="Enter your email" name="email" type="text" className={'form-control form-control2' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Enter your password" name="password" type="password" className={'form-control form-control2' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                <ErrorMessage name="password" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Confirm your password" name="confirmPassword" type="password" className={'form-control form-control2' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Enter your phone No" name="phone_no" type="number" className={'form-control form-control2' + (errors.phone_no && touched.phone_no ? ' is-invalid' : '')} />
                                                <ErrorMessage name="phone_no" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                {/* <label>Mother's maiden name</label> */}
                                                <Field placeholder="Mother's maiden name" name="security_question_answer" type="text" className={'form-control form-control2' + (errors.security_question_answer && touched.security_question_answer ? ' is-invalid' : '')} />
                                                <ErrorMessage name="security_question_answer" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        { this.state.formError ?
                                            <div className="error_label text-danger text-center mt20 mb20" role="alert">
                                                Please provide valid data
                                            </div>
                                        :null}
                                        {
                                            this.state.isloading ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
                                        }
                                        <div className="row product-action-btn mb-2 px-2">
                                            <div className="col-12">
                                                <div className="my-cart-btn">
                                                    <button type="submit" className="btn btn-primary w-100">Register</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                            
                        </div>
                    </div>
                </Fragment>
                
        
        )
    }
}


// export default Register
export default connect()(withRouter(Register));

import React,{Fragment} from 'react';
import CustomerDetailForm from '../CustomerDetailForm/CustomerDetailForm';
const DeliveryModalBody = (props)  => {
    return (
        <Fragment>
            <CustomerDetailForm
                initialValues={props.initialValues}
                onSubmitForm={props.onSubmitForm}
                isCustomerFormSavingLoader={props.isCustomerFormSavingLoader}
                inputAddressChangedHandler={props.inputAddressChangedHandler}
                isCalculatingDeliveryFee={props.isCalculatingDeliveryFee}
                delivery_type={props.delivery_type}
                delivery_amount={props.delivery_amount}
                query={props.query}
            />
        </Fragment>
    );
}

export default DeliveryModalBody;
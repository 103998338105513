import React,{Fragment} from 'react';
import OrderItemBlock from './OrderItemBlock'; 
import { withRouter } from 'react-router-dom';

const LoadMoreOrderHistoryItems = (props) => {
    return (
        <Fragment>
            <OrderItemBlock
                list={props.Orders}
            />
            {
                props.total_user_orders > 0 && props.total_user_orders > props.Orders.length ?
                    <div className="load_more_container text-center">
                        <div className="cursor-pointer"  onClick={()=> props.loadMore()}><i className="fas fa-angle-down show-more"></i></div>
                        
                    </div>
                :null
            }
            

    
        </Fragment>
    );
};

export default withRouter(LoadMoreOrderHistoryItems);
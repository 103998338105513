import React,{Fragment} from 'react';
import IngredientCheckbox from './IngredientCheckbox';
const LoadIngredientsItems = (props) => {
    // console.log('props.type')
    // console.log(props.type)
    const renderCards = () => (
        props.list ? 
            props.list.map((card,index)=>{
                if(props.menu_item_id === card.menu_item_id){
                    if(props.type === "free"){
                        if(card.ingredientType === 'included'){
                            return <IngredientCheckbox key={index} type={props.type} MenuItemType={props.MenuItemType}  handleOnClickCheckBox={props.handleOnClickCheckBox} menu_item_id={props.menu_item_id} menuItemName={props.menuItemName} name={card.name} price={card.price} ingredienttype={card.ingredientType}  id={card._id} isChecked={card.is_checked} isCheckedOld={true} value={card._id} />
                        }else if(card.ingredientType === "optional_free"){
                            return <IngredientCheckbox key={index} type={props.type} MenuItemType={props.MenuItemType}  handleOnClickCheckBox={props.handleOnClickCheckBox} menu_item_id={props.menu_item_id} menuItemName={props.menuItemName} name={card.name} price={card.price} ingredienttype={card.ingredientType}  id={card._id} isChecked={card.is_checked} isCheckedOld={false} value={card._id} />
                        }else if(card.ingredientType === "both"){
                            return <IngredientCheckbox key={index} type={props.type} MenuItemType={props.MenuItemType}  handleOnClickCheckBox={props.handleOnClickCheckBox} menu_item_id={props.menu_item_id} menuItemName={props.menuItemName} name={card.name} price={card.price} ingredienttype={card.ingredientType}  id={card._id} isChecked={card.is_checked} isCheckedOld={false} value={card._id} />
                        }
                    }else if(props.type === "paid"){
                        if(card.ingredientType === 'extras_charged'){
                            return <IngredientCheckbox key={index} type={props.type} MenuItemType={props.MenuItemType}  handleOnClickCheckBox={props.handleOnClickCheckBox} menu_item_id={props.menu_item_id} menuItemName={props.menuItemName} name={card.name} price={card.price} ingredienttype={card.ingredientType}  id={card._id} isChecked={card.is_checked} isCheckedOld={false} value={card._id} />
                        }
                    }
                }
            })
        : null
    )
    return (
        <Fragment>
                {/* <ComboMenuItemBlock
                    list={props.ComboMenuItems}
                    ComboItemPrice={props.ComboItemPrice}
                    handleOnClickCheckBox={(event)=> props.handleOnClickCheckBox(event)}
                /> */}
                {props.list ?
                    props.list.length === 0 ?
                        <div className="no_result">
                            {/* Sorry, no results */}
                        </div>
                    :null
                :null}
                { renderCards(props.list)}

    
        </Fragment>
    );
};

export default LoadIngredientsItems;
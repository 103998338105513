import React from 'react';
import { Link } from 'react-router-dom'

export const CartButton = props => {
    // console.log('props')
    // console.log(props)
    return (
        <Link to="/my-cart">
            <button className="btn btn-light w-100 position-relative">
                MY CART
                <span className="position-absolute">{props.cart.length} Item</span>
            </button>
        </Link>
    )
}

export default CartButton
import React,{Fragment} from 'react';
// import trashRedPng from '../../../common/images/icons/trash-red.png';
import RenderIngredientsOrderDetail from './RenderIngredientsOrderDetail';
const UserCartItemSubBlockOrderDetail = ({mainProduct,products,removeSubItemFromCart}) => {

    const renderItems = () => (
        products ?
            products.map((product,index)=>(
                <div className="col-12 pl35 mt10" key={index}>
                    <div className="row">
                        <div className="col-8">
                            <h4>{product.name} </h4>
                        </div>
                        <div className="col-4 text-right pl0">
                            <h4 className="pricing-tag">
                                ${(parseFloat(product.ingredientTotal) +parseFloat(product.combo_item_price)).toFixed(2) }
                                {/* <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon" onClick={()=> removeSubItemFromCart(mainProduct,product)} /> */}
                            </h4>
                            {/* <h4 className="pricing-tag">$ {(parseFloat(product.combo_item_price)).toFixed(2) }</h4> */}
                        </div>
                    </div>
                    <div className="row pl10">
                        <div className="col-12">
                            <ul>
                                {
                                    // RenderIngredientsOrderDetail(product.ingredients)
                                    product.isShowIngredientsList ? RenderIngredientsOrderDetail(product.ingredients) : null
                                    // product.ingredients.length ? product.ingredients.map((ingredient,index) =>
                                    //         (<span key={index}> {ingredient.name} , {index} </span>)) : ''
                                }
                            </ul>
                            
                        </div>
                        {/* <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                            <div className="cursor-pointer" onClick={()=> removeSubItemFromCart(mainProduct,product)}><img src={trashRedPng} alt="trash" className="mw-100" /></div>
                        </div> */}
                    </div>
                    
                </div>
            ))

        :null
    )


    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );
};

export default UserCartItemSubBlockOrderDetail;
import React, { Fragment } from 'react';
const AuthModalBody = (props) => {
    return (
        <Fragment>
            {/* <Link to='/address-details'>
                <button className="btn btn-primary w-100">
                    Complete Order
                </button>
            </Link> */}
            <div className="row product-action-btn mb-2 px-2">
                <div className="col">
                    <div className="my-cart-btn">
                        {/* <Link to='/address-details/delivery' > */}
                        <button onClick={() => props.handleOnClickBtnAuthType('continue_as_guest')} className="btn btn-primary w-100" type="button" name="create_account" value="create_account">
                            Continue as guest
                                </button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>
            <div className="row product-action-btn mb-2 px-2">
                <div className="col">
                    <div className="my-cart-btn">
                        {/* <Link to='/address-details/pickup' > */}
                        <button onClick={() => props.handleOnClickBtnAuthType('login')} className="btn btn-primary w-100" type="button" name="login" value="login" >
                            Login
                                </button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>    
            <div className="row product-action-btn mb-2 px-2">
                <div className="col">
                    <div className="my-cart-btn">
                        {/* <Link to='/address-details/delivery' > */}
                        <button onClick={() => props.handleOnClickBtnAuthType('create_account')} className="btn btn-primary w-100" type="button" name="create_account" value="create_account">
                            Create account
                        </button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>    
            
        </Fragment>
    );
}

export default AuthModalBody;
import React, { Component } from 'react';
// import MyButton from './button';

import { connect } from 'react-redux';
// import { addToCart } from '../../actions/user_actions';
// import chickenKabobPng from '../../../common/images/products/chicken-kabob.png'
import {S3_URL} from '../../../../utils/misc';
// import chickenKabobPng from '../../common/images/products/chicken-kabob.png'
import { withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { fetchMenuItemCombosByMenuItemId  } from '../../../../../actions/menu_actions';
import LoadingSpinner from '../../../../../components/utils/LoadingSpinner';

class MenuItem extends Component {
    _isMounted = false;
    state = {
        loading: false,
    }

    constructor(props) {
        super(props)
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    renderCardImage(images){
        if(images.length > 0){
            return images[0].url
        } else {
            return '/images/image_not_availble.png'
        }
    }

    onClickMenuItem(menuItem,event){
        // console.log('menuItem in onClickMenuItem')
        // console.log('menuItem in onClickMenuItem')
        // console.log('menuItem in onClickMenuItem')
        // console.log(menuItem)
        // console.log('menuItem.sizes.length')
        // console.log(menuItem.sizes.length)
        // console.log('menuItem.comboItems')
        // console.log(menuItem.comboItems)
        this.setState({loading:true});
        if(menuItem.special_modifiers && menuItem.special_modifiers.length > 0){
            this.props.history.push(`/menu-item/select-menu-item-special-modifiers/${menuItem._id}?menuCategoryId=${this.props.menuCategoryId}`)    
        }else if(menuItem.sizes && menuItem.sizes.length > 0){
            // console.log('i am n')
            // console.log('i am n')
            // console.log('i am n')
            // console.log('i am n')
            this.props.history.push(`/menu-item/select-menu-item-size/${menuItem._id}?menuCategoryId=${this.props.menuCategoryId}`)    
        }
        this.props.fetchMenuItemCombosByMenuItemId(menuItem._id).then(response =>{
            // console.log('response in home fetchMenuItemCombosByMenuItemId')
            // console.log(response)
            if(this._isMounted){
                this.setState({loading:false})
                const MenuItemCombos = this.props.MenuItemCombos;
                // console.log('MenuItemCombos')
                // console.log(MenuItemCombos)
                if(MenuItemCombos.length > 0){
                    this.props.history.push(`/menu-item/add-combo-items/${menuItem._id}?menuCategoryId=${this.props.menuCategoryId}`)
                }else{
                    let selectedCstateomboItems = [];
                    selectedCstateomboItems = selectedCstateomboItems.join(",");
                    // Or we can use a localstorae here 
                    // alert('ww')
                    // this.props.history.push(`/menu-item/add-ingredients/${menuItem._id}`, { selectedComboItems: [], color: 'green' });
                    this.props.history.push({
                        pathname: `/menu-item/add-ingredients/${menuItem._id}?selectedCstateomboItems=${selectedCstateomboItems}&menuCategoryId=${this.props.menuCategoryId}`,
                    
                    }) 
                    // this.props.history.push(`/menu-item/add-ingredients/${menuItem._id}`)
                } 
            }
            // this.setState({loading:false})
        });
        /* if(menuItem.comboItems.length > 0){
            this.props.history.push(`/menu-item/add-combo-items/${menuItem._id}`)
        }else{
            let selectedCstateomboItems = [];
            selectedCstateomboItems = selectedCstateomboItems.join(",");
            // Or we can use a localstorae here 
            // alert('ww')
            // this.props.history.push(`/menu-item/add-ingredients/${menuItem._id}`, { selectedComboItems: [], color: 'green' });
            this.props.history.push({
                pathname: `/menu-item/add-ingredients/${menuItem._id}?selectedCstateomboItems=${selectedCstateomboItems}`,
              
            }) 
            // this.props.history.push(`/menu-item/add-ingredients/${menuItem._id}`)
        } */
        
    }


    render() {
        if(this.state.loading){
            return (
                // <Fragment>
                    <LoadingSpinner />
                // </Fragment>
                
            )
        }
        const props = this.props;
        // console.log('props')
        // console.log(props)
        const MenuItemCombos = this.props.MenuItemCombos;
        // console.log('MenuItemCombos')
        // console.log(MenuItemCombos)
        return (
            <div className={`card_item_wrapper `}>
                {/* <Link className="btn btn-primary w-100 position-relative hide"
                    to={{
                        pathname: `/menu-item/add-ingredients/${props._id}`,
                        selectedComboItems: [] // your data array of objects
                    }}
                    
                > Countinue </Link> */}
                
                <div className="product-item d-block pl-2 pr-3 py-2 mb-3 cursor-pointer" onClick={this.onClickMenuItem.bind(this,props)}>
                {/* <a href="#" className="product-item d-block pl-2 pr-3 py-2 mb-3" onClick={this.onClickMenuItem.bind(this,props)}> */}
                    <div className="row">
                        <div className="col-4 col-lg-3 pr-0">
                            <div className="prod-img">
                                <img src={`${S3_URL}${props.s3_image_path}`} alt="products" className="mw-100" />
                            </div>
                        </div>
                        <div className="col-8 col-lg-9">
                            <div className="prod-info">
                                <h4>{props.name}</h4>
                                <p>
                                    {props.description}
                                </p>
                                <h4 className="text-right font-italic">$ {props.price}</h4>
                            </div>
                        </div>
                    </div>
                {/* </a> */}
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        MenuItemCombos : state.MenuItems.MenuItemCombos
    }
}

export default connect(mapStateToProps,{fetchMenuItemCombosByMenuItemId})(withRouter(MenuItem));
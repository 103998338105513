import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import rightArrowRedPng from '../../common/images/icons/right-arrow-red.png';
import LoadingSpinner from '../../utils/LoadingSpinner';
import dropLeftPng from '../../common/images/icons/drop-left.png';
import { fetchUserOrders,clearOrderHistory  } from '../../../actions/order_actions';
import LoadMoreOrderHistoryItems from './components/LoadMoreOrderHistoryItems';
class OrderHistory extends Component {
    state = {
        loading: false,
        limit:5,
        skip:0,
        Orders:[]
    }
    constructor(props) {
        super(props)
    }
    componentWillUnmount(){
        this.props.clearOrderHistory();
    }
    componentDidMount() {
        // console.log('this.props.order');
        // console.log(this.props.order);
        // console.log('this.props.user');
        // console.log(this.props.user.userData._id);
        this.setState({loading:true});
        this.props.fetchUserOrders(
                this.props.user.userData._id,
                this.state.skip,
                this.state.limit,
                this.props.order.userOrders
            ).then(response => {
            // console.log('this.props.order');
            // console.log(this.props.order);
            this.setState({loading:false});
        });
        /* const cart = this.props.cart;
        if(cart.length === 0){
            showMsg("Please add some items to the cart.",'info');
            this.props.history.push('/');
        }

        let userData = this.props.user.userData;
        if(userData.isAuth){
            let address = userData.address;
            this.setState(
                {
                    city: address,
                    query: address,
                }
            );
        } */
        // console.log('this.props.menuCategories')
        // console.log(this.props.menuCategories)
    }
    LoadMoreOrderHistoryItemsF = () => {
        this.setState({loading:true});
        let skip = this.state.skip + this.state.limit;
        this.props.fetchUserOrders(
                this.props.user.userData._id,
                skip,
                this.state.limit,
                this.props.order.userOrders
            ).then(response => {
            console.log('this.props.order');
            console.log(this.props.order);
            this.setState({skip,loading:false});
        });
        /* this.props.dispatch(getMenuItemsByCategoryId(
            skip,
            this.state.limit,
            this.state.menuCategoryId,
            this.props.MenuItems.MenuItems
        )).then(()=>{
            this.setState({
                loading:false,
                skip
            })
        }) */
    }
    render() {
        // console.log('this.props.order')
        // console.log(this.props.order)
        const order = this.props.order;
        
        if (Object.keys(order).length === 0 || order.userOrders === undefined) {
            return (
                <LoadingSpinner subClass={'mt10 mb10'} />
            )
        }else{

        }
        // const userOrders = order.userOrders; 
        // const userOrders = order.userOrders; 
        return (
                <Fragment>
                    <header className="container inner-header">
                        <div className="row">
                            <div className="col-12 mb20">
                                <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={this.props.history.goBack} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>Order History</h4>
                            </div>
                        </div>
                    </header>
                    <div className="inner-body">
                        <div className="container my-2">
                            {
                                order.userOrders !== 'undefined' && order.userOrders !== undefined ?
                                    <LoadMoreOrderHistoryItems
                                        limit={this.state.limit}
                                        total_user_orders={order.total_user_orders}
                                        Orders={order.userOrders}
                                        loadMore={()=> this.LoadMoreOrderHistoryItemsF()}
                                    />
                                :null
                            }
                            {
                                this.state.loading ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
                            }
                                
                        </div>
                    </div>
                </Fragment>
                
        
        )
    }
}
function mapStateToProps(state) {
    // console.log('state in ingr')
    // console.log(state)
    return { 
        order : state.order
    };
}

export default connect(mapStateToProps, { fetchUserOrders,clearOrderHistory })(withRouter(OrderHistory));

import React, { Component } from 'react';
import { BrowserRouter, Switch, Route   } from 'react-router-dom'
import { connect } from 'react-redux';
import * as actions from './actions';
// import MobileLogo from './components/layout/MobileLogo';
// import TopBar from './components/layout/Header';
// import SideMenu from './components/layout/SideMenu';
import Header from './components/layout/Header';
// import FooterCopyRight from './components/layout/FooterCopyRight';
// Pages
import Home from './components/Pages/Home/Home';
import AddComboItemsToMenuItem from './components/Pages/AddComboItemsToMenuItem/AddComboItemsToMenuItem';
import MenuItemSelectSize from './components/Pages/MenuItemSelectSize/MenuItemSelectSize';
import MenuItemSelectSpecialModifiers from './components/Pages/MenuItemSelectSpecialModifiers/MenuItemSelectSpecialModifiers';
import AddressDetails from './components/Pages/AddressDetails/AddressDetails';
import Login from './components/Pages/Auth/Login';
import Register from './components/Pages/Auth/Register';
import ResetUser from './components/Pages/Auth/ResetUser';
import ResetUserPassword from './components/Pages/Auth/ResetUserPassword';
// import OrderDetails from './components/Pages/OrderDetails/OrderDetails';
import Cart from './components/Pages/Cart/Cart';
import OrderReview from './components/Pages/OrderReview/OrderReview';
// import DetailPage from './components/Pages/DetailPage/DetailPage';
import OrderHistory from './components/Pages/OrderHistory/OrderHistory';
import OrderDetails from './components/Pages/OrderDetails/OrderDetails';
import Favorites from './components/Pages/Favorites/Favorites';
import Ingredient from './components/Pages/Ingredient/Ingredient';
// import PageNotFound from './components/utils/page_not_found';
import ResturantClosedPage from './components/utils/ResturantClosedPage';
import Auth from './hoc/auth';
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
let token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
} else {
  axios.defaults.headers.common['Authorization'] = null;
}

class App extends Component {
  componentDidMount() {
    // this.props.fetchUser();
  }
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <ToastContainer />
          {/* <SlickNav /> */}
          {/* <MobileLogo /> */}
          {/* <TopBar /> */}
          {/* <SideMenu /> */}
          <div className="row py-15" >
            <Header />
            {/* <HashRouter> */}
              <Switch>
                <Route exact path='/' component={Auth(Home,true)} />
                <Route exact path='/login/:is_checkout_redirect?/:delivery_type?' component={Auth(Login,false,true)} />
                <Route exact path='/register' component={Auth(Register,false,true)} />
                <Route exact path='/reset_password/:token/:is_checkout_redirect?/:delivery_type?' component={Auth(ResetUserPassword,false,true)} />
                <Route exact path='/forgot-password/:is_checkout_redirect?/:delivery_type?' component={Auth(ResetUser,false,true)} />
                <Route exact path='/address-details/:delivery_type/:auth_type?' component={Auth(AddressDetails,true)} />
                <Route exact path='/my-cart' component={Auth(Cart,true)} />
                <Route exact path='/order-review' component={Auth(OrderReview,true)} />
                <Route exact path='/Favorites' component={Auth(Favorites,true,true)} />
                <Route exact path='/ingredient' component={Auth(Ingredient,true)} />
                {/* <Route exact path='/order-details/:order_id' component={Auth(OrderDetails,true,true)} /> */}
                <Route exact path='/order-details/:order_id' component={Auth(OrderDetails,false)} />
                <Route exact path='/order-history' component={Auth(OrderHistory,true,true)} />
                <Route exact path='/resturant-closed' component={Auth(Home,true)} />
                {/* <Route exact path='/resturant-closed' component={Auth(ResturantClosedPage)} /> */}
                <Route exact path="/menu-item/add-combo-items/:mitem_id" component={Auth(AddComboItemsToMenuItem,false)}/>
                <Route exact path="/menu-item/add-ingredients/:mitem_id" component={Auth(Ingredient,false)}/>
                <Route exact path="/menu-item/select-menu-item-special-modifiers/:mitem_id" component={Auth(MenuItemSelectSpecialModifiers,false)}/>
                <Route exact path="/menu-item/select-menu-item-size/:mitem_id" component={Auth(MenuItemSelectSize,false)}/>
              </Switch>
            {/* </HashRouter> */}
          </div>
          {/* <Footer /> */}
          {/* <FooterCopyRight /> */}
        </div>
      </BrowserRouter>
      
    );
  }
}

// export default App;
export default connect(null, actions)(App);

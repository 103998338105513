const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)
const BASE_URL = (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_BASE_URL_PRODUCTION : process.env.REACT_APP_BASE_URL;

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

function sendSubscription(subscription) {
    const timestamp = new Date().getTime();
    const token = (timestamp) + timestamp +Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)+timestamp;
    let data = {
        subscription,
        token
    }
    console.log('i am in send')
    console.log('i am in send')
    console.log('i am in send')
    console.log('i am in send')
    if (localStorage.getItem("push_notification_subscription_token") === null) {
        localStorage.setItem("push_notification_subscription_token", token);
        return fetch(`${BASE_URL}/notifications/subscribe`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}

export function subscribeUser() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(function (registration) {
            if (!registration.pushManager) {
                console.log('Push manager unavailable.')
                return
            }
            console.log(localStorage.getItem("push_notification_subscription_token"))
            console.log(localStorage.getItem("push_notification_subscription_token"))
            console.log(localStorage.getItem("push_notification_subscription_token"))
            console.log(localStorage.getItem("push_notification_subscription_token"))
            if (localStorage.getItem("push_notification_subscription_token") === null) {
                registration.pushManager.getSubscription().then(function (existedSubscription) {
                    if (existedSubscription === null) {
                        console.log('No subscription detected, make a request.')
                        registration.pushManager.subscribe({
                            applicationServerKey: convertedVapidKey,
                            userVisibleOnly: true,
                        }).then(function (newSubscription) {
                            console.log('New subscription added.')
                            sendSubscription(newSubscription)
                        }).catch(function (e) {
                            if (Notification.permission !== 'granted') {
                                console.log('Permission was not granted.')
                            } else {
                                console.error('An error ocurred during the subscription process.', e)
                            }
                        })
                    } else {
                        console.log('Existed subscription detected.')
                        sendSubscription(existedSubscription)
                    }
                })
            }
        })
            .catch(function (e) {
                console.error('An error ocurred during Service Worker registration.', e)
            })
    }
}

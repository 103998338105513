import React,{Fragment} from 'react';
import LoadingSpinner from '../../../../../../utils/LoadingSpinner';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const CustomerDetailForm = (props) => {
    const { initialValues, isCustomerFormSavingLoader, inputAddressChangedHandler, query, userData, delivery_type } = props
    // const { onSubmitForm,onToken,initialValues,isCustomerFormSavingLoader, handleSubmit, inputAddressChangedHandler, query, userData,delivery_type } = props
    // const { isCustomerFormSavingLoader, handleSubmit, pristine, reset, submitting, inputAddressChangedHandler, query, userData } = props
    let CustomerDetailFormSchema = {};
    let objCustomerDetailFormSchema = {
        fullname: Yup.string().required('Name is required'),
        phone_no: Yup.string()
            .min(10, 'Phone No must be atleast of 10 numbers')
            .required('Phone No is required'),
    }

    CustomerDetailFormSchema = Yup.object().shape(objCustomerDetailFormSchema);
    // console.log('CustomerDetailFormSchema')
    // console.log('CustomerDetailFormSchema')
    // console.log(CustomerDetailFormSchema)
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CustomerDetailFormSchema}
            onSubmit={props.onSubmitForm}
            render={({ errors, status, touched }) => (
                <Form>
                    <div className="row bg-grey border-round product-bill p-0">
                        <div className="col-12">
                            <Field placeholder="Name" name="fullname" type="text" className={'form-control form-control2' + (errors.fullname && touched.fullname ? ' is-invalid' : '')} />
                            <ErrorMessage name="fullname" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                        </div>
                    </div>
                    
                    {
                        delivery_type === 'delivery' ?
                        <Fragment>
                            <div className="row bg-grey border-round product-bill p-0">
                                <div className="col-12">
                                    <input placeholder="Address" type="text" className="form-control form-control2" id="autocomplete" value={query} onChange={(event) => inputAddressChangedHandler(event)} />
                                    {
                                        props.isCalculatingDeliveryFee === false ?
                                        <Fragment>
                                            {
                                                props.delivery_amount !== 0 ?
                                                    <p className="txtDeliveryFeeMention">Delivery fee: ${props.delivery_amount} </p>
                                                : null
                                            }
                                        </Fragment>
                                            : <LoadingSpinner subClass={'mt10 mb10'} />
                                    }
                                </div>
                            </div>
                            <div className="row bg-grey border-round product-bill p-0">
                                <div className="col-12">
                                    <Field placeholder="Appartment/Unit Number" name="appartment_unit_number" type="text" className={'form-control form-control2' + (errors.appartment_unit_number && touched.appartment_unit_number ? ' is-invalid' : '')} />
                                    <ErrorMessage name="appartment_unit_number" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                </div>
                            </div>
                        </Fragment>
                        : null
                    }
                    
                    <div className="row bg-grey border-round product-bill p-0">
                        <div className="col-12">
                            <Field placeholder="Phone No" name="phone_no" type="number" className={'form-control form-control2' + (errors.phone_no && touched.phone_no ? ' is-invalid' : '')} />
                            <ErrorMessage name="phone_no" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                        </div>
                    </div>
                    

                    {
                        isCustomerFormSavingLoader === false ?
                            <div className="row product-action-btn mb-2 px-2">
                                <div className="col-12">
                                    <div className="my-cart-btn">
                                        <button type="submit" className="btn btn-primary w-100">Submit</button>
                                    </div>
                                </div>
                            </div>
                            : <LoadingSpinner subClass={'mt10 mb10'} />
                    }
                </Form>
            )}
        />

    )
}



export default CustomerDetailForm;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import CartPng from '../../common/images/icons/cart.png';
import PreparingPng from '../../common/images/icons/preparing.png';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { fetchOrderByOId } from '../../../actions/order_actions';
import { formatOrderDate, formatOrderDateNumber, getParameterByName, showMsg } from '../../utils/general';
import UserCartItemBlockOrderDetail from './components/UserCartItemBlockOrderDetail';
import { formatExistingOrderDateTimeOnly } from '../../utils/general';
import dropLeftPng from '../../common/images/icons/drop-left.png';
import { socket } from '../../layout/Header';
import { countOrderItems, calculateFinalTotalOfOrder } from '../../utils/OrdersPages/OrdersHelpers';
class OrderDetails extends Component {
    state = {
        order_id: '',
        loading: true,
    }
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        let order_id = this.props.match.params.order_id;
        let userData = this.props.user.userData;
        const redirect_to = getParameterByName('redirect_to');
        // console.log('order_id')
        // console.log(order_id)
        this.setState({ order_id, loading: true, redirect_to });
        this.props.fetchOrderByOId(order_id).then(response => {
            const objOrder = this.props.order;
            console.log('objOrder')
            console.log('objOrder')
            console.log(objOrder)
            if (objOrder.error) {
                showMsg(objOrder.msg, 'error');
                this.props.history.push(`/`);
            } else {
                this.setState({ loading: false });
                if (!userData.isAuth && objOrder.Order.author) {
                    showMsg('Access denied', 'error');
                    this.props.history.push(`/`);
                }
                if (userData.isAuth && objOrder.Order.author && userData._id.toString() !== objOrder.Order.author._id.toString()) {
                    showMsg('Access denied', 'error');
                    this.props.history.push(`/`);
                }
            }
        });
        // console.log('userData')
        // console.log('userData')
        // console.log(userData)
        socket.on("webApp-orders-channel:ChangeOrderStatusToPreparing", (objOrder) => {
            console.log('objOrder in ChangeOrderStatusToPreparing');
            console.log(objOrder);
            if ((userData.isAuth && objOrder.author && userData._id === objOrder.author._id) || (this.props.order.Order._id === objOrder._id && objOrder.auth_type === 'guest')) {
                // if(userData.isAuth && userData._id === objOrder.author._id){
                // console.log('i am loggged in user order')
                // console.log('i am loggged in user order')
                // console.log('i am loggged in user order')
                this.props.fetchOrderByOId(order_id).then(response => {
                });
            }

        });


    }
    render() {
        const objOrder = this.props.order.Order;
        // console.log('objOrder before')
        // console.log(objOrder)
        if (objOrder === undefined || (!objOrder)) {
            return (
                <LoadingSpinner />
            )
        }
        // console.log('objOrder')
        // console.log(objOrder)
        // console.log('this.state.redirect_to')
        // console.log(this.state.redirect_to)
        // console.log(formatOrderDate(new Date))
        return (
            <Fragment>
                <header className="container inner-header">
                    <div className="row">
                        <div className="col-12 mb20">
                            {
                                (this.state.redirect_to === false || this.state.redirect_to === null) ?
                                    <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={this.props.history.goBack} />
                                    : <Link to="/"> <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" /></Link>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4>Ticket Number {objOrder.ticket_no}</h4>
                        </div>
                    </div>
                </header>
                <div className="inner-body inner-body-custom myOrderDetailPage">
                    <div className="container my-2">

                        <div className="row order-process-bar text-center">
                            <div className="col-6">
                                <small className="text-danger">{formatOrderDateNumber(objOrder.createdAt)}</small>
                                <p>Order Placed</p>
                                <div className="order-process-success d-flex justify-content-center">
                                    <span className="order-process-icon d-flex align-items-center justify-content-center"><img src={CartPng} alt="cart" className="mw-100" /></span>
                                </div>
                            </div>
                            <div className="col-6">
                                {
                                    (objOrder.status === 'accepted' || objOrder.status === 'done' || objOrder.status === 'cash') ?
                                        <small className="text-danger">Order ready at {formatExistingOrderDateTimeOnly(objOrder.order_completion_datetime)}</small>
                                        :
                                        <small className="text-danger invisible">{formatOrderDateNumber(objOrder.order_accepted_datetime)}</small>
                                }

                                <p>Preparing</p>
                                <div className="order-process-success d-flex justify-content-center">
                                    {/* if we remove the isPreparing class then we will */}
                                    <span className={`order-process-icon d-flex align-items-center justify-content-center ${(objOrder.status === 'accepted' || objOrder.status === 'done' || objOrder.status === 'cash') ? '' : 'isPreparing'}`}><img src={PreparingPng} alt="cart" className="mw-100" /></span>
                                </div>
                            </div>
                            {/* <div className="col-3">
                                    <small className="text-danger">-</small>
                                    <p>Dispatched</p>
                                    <div className="d-flex justify-content-center">
                                        <span className="order-process-icon d-flex align-items-center justify-content-center"><img src="img/icons/cart.png" alt="cart" className="mw-100" /></span>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <small className="text-danger">-</small>
                                    <p>Deliverd</p>
                                    <div className="d-flex justify-content-center">
                                        <span className="order-process-icon d-flex align-items-center justify-content-center"><img src="img/icons/cart.png" alt="cart" className="mw-100" /></span>
                                    </div>
                                </div> */}
                        </div>
                        <div className="row bg-grey product-bill">
                            <div className="col-8 mb-3">
                                <h4>{formatOrderDate(objOrder.createdAt)}</h4>
                            </div>
                            <div className="col-4">
                                <h4 className="pricing-tag text-danger text-right"><span>
                                {
                                    objOrder.is_created_before_card_processing_fee === false ?
                                    <Fragment> ${calculateFinalTotalOfOrder(objOrder,objOrder.delivery_payment_method,objOrder.card_processing_fee)}</Fragment>
                                    :<Fragment> ${parseFloat(objOrder.total_amount_after_tax_delivery_charges).toFixed(2)}</Fragment>
                                }
                                
                                </span></h4>
                            </div>
                            <div className="col-12 billing-address">
                                <p>{countOrderItems(objOrder.order_details)} Items order</p>
                                {/* <p>{parseInt(1)+parseInt(objOrder.order_details[0].selectedComboItems.length)} Items order</p> */}
                                <p>
                                    {objOrder.address}
                                </p>
                                {
                                    objOrder.delivery_type === 'delivery' && objOrder.appartment_unit_number !== '' ?
                                    <p>{objOrder.appartment_unit_number}</p>
                                    : null
                                }
                                <p>{objOrder.phone_no}</p>
                                <p>{objOrder.notes}</p>
                            </div>
                        </div>
                        <UserCartItemBlockOrderDetail
                            products={objOrder.order_details}
                            type="cart"
                        />
                        {
                            objOrder.is_created_before_card_processing_fee === false ?

                                <div className="row text-right row-totals mb10">
                                    <div className="col-12">
                                        <p>Sub total : ${parseFloat(objOrder.subTotal).toFixed(2)} </p>
                                    </div>
                                    <div className="col-12">
                                        <p>Tax : ${parseFloat(objOrder.taxAmount).toFixed(2)} </p>
                                    </div>
                                    <div className="col-12">
                                        <p>Total : ${parseFloat(objOrder.total).toFixed(2)} </p>
                                    </div>
                                    {
                                        objOrder.delivery_type === 'delivery' ?
                                        <div className="col-12 mt10">
                                            <p>Delivery fee : ${parseFloat(objOrder.delivery_amount).toFixed(2)} </p>
                                        </div>
                                        : null
                                    }
                                    {
                                        objOrder.delivery_type === 'delivery' && objOrder.delivery_payment_method === 'credit' ?
                                        <div className="col-12">
                                            <p>Card processing fee : ${parseFloat(objOrder.card_processing_fee).toFixed(2)} </p>
                                        </div>
                                        : null
                                    }
                                    {
                                        objOrder.delivery_type === 'delivery'?
                                            <div className="col-12">
                                                <p>Total including fee : ${calculateFinalTotalOfOrder(objOrder,objOrder.delivery_payment_method,objOrder.card_processing_fee)} </p>
                                            </div>
                                        : null
                                    }
                                </div>
                                :
                                <div className="row text-right row-totals mb10">
                                    <div className="col-12">
                                        <p>Sub total : ${parseFloat(objOrder.total_amount).toFixed(2)} </p>
                                    </div>
                                    <div className="col-12">
                                        <p>Tax : ${parseFloat(objOrder.tax_amount).toFixed(2)} </p>
                                    </div>
                                    <div className="col-12">
                                        <p>Total : ${parseFloat(objOrder.total_amount_after_tax_delivery_charges).toFixed(2)} </p>
                                    </div>
                                </div>
                        }





                    </div>
                </div>
            </Fragment>


        )
    }
}


// export default OrderDetails;
function mapStateToProps(state) {
    // console.log('state in ingr')
    // console.log(state)
    return {
        order: state.order
    };
}

export default connect(mapStateToProps, { fetchOrderByOId })(withRouter(OrderDetails));

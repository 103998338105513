import React, { Component, Fragment } from 'react'
import rightArrowRedPng from '../../common/images/icons/right-arrow-red.png';
class Favorites extends Component {
  render() {
    return (
            <Fragment>
                <header className="container inner-header header-bg-bottom mb-108">
                    <div className="row">
                        <div className="col-12">
                            <h4>Favorites</h4>
                        </div>
                    </div>
                </header>
                <div className="inner-body">
                    <div className="container my-2">

                        <div className="row bg-grey product-bill">
                            <div className="col-8">
                                <h4>Cheese Burger</h4>
                            </div>
                            <div className="col-4 text-right">
                                <h4 className="pricing-tag">$11.95</h4>
                            </div>
                            <div className="col-10">
                                <p>tomato, Mayyanoise, cheese Slice, large Egg</p>
                            </div>
                            <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                                <a href="#"><img src={rightArrowRedPng} alt="arrow" className="mw-100" /></a>
                            </div>
                        </div>

                        <div className="row bg-grey product-bill">
                            <div className="col-8">
                                <h4>Cheese Burger</h4>
                            </div>
                            <div className="col-4 text-right">
                                <h4 className="pricing-tag">$11.95</h4>
                            </div>
                            <div className="col-10">
                                <p>tomato, Mayyanoise, cheese Slice, large Egg</p>
                            </div>
                            <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                                <a href="#"><img src={rightArrowRedPng} alt="arrow" className="mw-100" /></a>
                            </div>
                        </div>

                        <div className="row bg-grey product-bill">
                            <div className="col-8">
                                <h4>Cheese Burger</h4>
                            </div>
                            <div className="col-4 text-right">
                                <h4 className="pricing-tag">$11.95</h4>
                            </div>
                            <div className="col-10">
                                <p>tomato, Mayyanoise, cheese Slice, large Egg</p>
                            </div>
                            <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                                <a href="#"><img src={rightArrowRedPng} alt="arrow" className="mw-100" /></a>
                            </div>
                        </div>

                        <div className="row bg-grey product-bill">
                            <div className="col-8">
                                <h4>Cheese Burger</h4>
                            </div>
                            <div className="col-4 text-right">
                                <h4 className="pricing-tag">$11.95</h4>
                            </div>
                            <div className="col-10">
                                <p>tomato, Mayyanoise, cheese Slice, large Egg</p>
                            </div>
                            <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                                <a href="#"><img src={rightArrowRedPng} alt="arrow" className="mw-100" /></a>
                            </div>
                        </div>

                        <div className="row bg-grey product-bill">
                            <div className="col-8">
                                <h4>Cheese Burger</h4>
                            </div>
                            <div className="col-4 text-right">
                                <h4 className="pricing-tag">$11.95</h4>
                            </div>
                            <div className="col-10">
                                <p>tomato, Mayyanoise, cheese Slice, large Egg</p>
                            </div>
                            <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                                <a href="#"><img src={rightArrowRedPng} alt="arrow" className="mw-100" /></a>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
            
       
    )
  }
}


export default Favorites

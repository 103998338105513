import React,{Fragment} from 'react';
const MenuItemSpecialModifier = (props) => {

    // console.log('props in menusize')
    // console.log('props in menusize')
    // console.log('props in menusize')
    // console.log(props)
    const SpecialModifiers = props.SpecialModifiers;
    return (
        <Fragment>
            {
                (SpecialModifiers[0] && SpecialModifiers[0].special_modifier_btn_one && SpecialModifiers[0].special_modifier_btn_one) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block`} onClick={()=>props.handleOnClickMenuItemSpecialModifier(SpecialModifiers[0],SpecialModifiers[0].special_modifier_btn_one,SpecialModifiers[0].special_modifier_btn_price_one)}>
                    {SpecialModifiers[0].special_modifier_btn_one}
                </button>
            : null 
            }
            {
                (SpecialModifiers[1] && SpecialModifiers[1].special_modifier_btn_two && SpecialModifiers[1].special_modifier_btn_two) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block`} onClick={()=>props.handleOnClickMenuItemSpecialModifier(SpecialModifiers[1],SpecialModifiers[1].special_modifier_btn_two,SpecialModifiers[1].special_modifier_btn_price_two)}>
                    {SpecialModifiers[1].special_modifier_btn_two}
                </button>
            : null 
            }
            {
                (SpecialModifiers[2] && SpecialModifiers[2].special_modifier_btn_three && SpecialModifiers[2].special_modifier_btn_three) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block`} onClick={()=>props.handleOnClickMenuItemSpecialModifier(SpecialModifiers[2],SpecialModifiers[2].special_modifier_btn_three,SpecialModifiers[2].special_modifier_btn_price_three)}>
                    {SpecialModifiers[2].special_modifier_btn_three}
                </button>
            : null 
            }
            {
                (SpecialModifiers[3] && SpecialModifiers[3].special_modifier_btn_four && SpecialModifiers[3].special_modifier_btn_four) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block`} onClick={()=>props.handleOnClickMenuItemSpecialModifier(SpecialModifiers[3],SpecialModifiers[3].special_modifier_btn_four,SpecialModifiers[3].special_modifier_btn_price_four)}>
                    {SpecialModifiers[3].special_modifier_btn_four}
                </button>
            : null 
            }
            {
                (SpecialModifiers[4] && SpecialModifiers[4].special_modifier_btn_five && SpecialModifiers[4].special_modifier_btn_five) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block`} onClick={()=>props.handleOnClickMenuItemSpecialModifier(SpecialModifiers[4],SpecialModifiers[4].special_modifier_btn_five,SpecialModifiers[4].special_modifier_btn_price_five)}>
                    {SpecialModifiers[4].special_modifier_btn_five}
                </button>
            : null 
            }
            {
                (SpecialModifiers[5] && SpecialModifiers[5].special_modifier_btn_six && SpecialModifiers[5].special_modifier_btn_six) ?
                <button className={`btn btn-danger btn-circle btn-xl btn-block`} onClick={()=>props.handleOnClickMenuItemSpecialModifier(SpecialModifiers[5],SpecialModifiers[5].special_modifier_btn_six,SpecialModifiers[5].special_modifier_btn_price_six)}>
                    {SpecialModifiers[5].special_modifier_btn_six}
                </button>
            : null 
            }

            
        </Fragment>
    );
};

export default MenuItemSpecialModifier;
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Field, reduxForm } from 'redux-form'
import {showMsg,addTimeToCurrentDateTime,getDeliveryAmount } from '../../utils/general';
// import PlacesAutocomplete, {geocodeByAddress,getLatLng,} from 'react-places-autocomplete';
// Import React Scrit Libraray to load Google object
import Script from 'react-load-script';
import AddressDetailForm from './components/AddressDetailForm';
import { submitCreateOrder  } from '../../../actions/order_actions';
import { clearCart  } from '../../../actions/cart_actions';
import { fetchResturantTax, fetchRestaurantProvince, fetchResturantDeliveryFees,fetchResturantPhoneNumber,fetchResturantAddress,fetchResturantStripeApiKeys  } from '../../../actions/settings_actions';
// import { socket } from '../../layout/Header';
import dropLeftPng from '../../common/images/icons/drop-left.png';
// import oldLogoPng from '../../common/images/mini-logo.png'
// import {Elements, StripeProvider} from 'react-stripe-elements';
// import StripeCheckout from "react-stripe-checkout";
// import { Button } from 'reactstrap';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { validateDeliveryAmount } from '../../utils/AddressDetailPage/Validations';
import { calculateTotalHelper } from '../../utils/OrdersPages/OrdersHelpers';
// import { Field } from 'formik';

class AddressDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        // this.state = { address: '' };
        // Declare State
        this.state = {
            origin:'',
            // origin:'6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada',//Sofos Addres
            // origin:'1805 Tecumseh Rd W, Windsor, ON N9B 1V4, Canada',//Buskers Address
            // origin:'Forks n Knives Crisp n Grill, Susan Road, Faisalabad, Pakistan',
            
            city: '',
            query: '',
            delivery_type:'',
            isOrderSavingLoader:false,
            formData : {},
            tax : 0,
            taxAmount:0,
            subTotal:0,
            total:0,
            totalAmount:0,
            delivery_amount:0,
            distance_in_kilo:'',
            cartDetail:[],
            deliveryFees:{},
            isCalculatingDeliveryFee: false,
            restaurant_phone_number:'',
            restaurant_address:'',
            restaurant_province:'',
            auth_type:'',
        };
        
  
        // Bind Functions
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        // Stripe
        
        // this.handleStripeScriptLoad = this.handleStripeScriptLoad.bind(this);
        this.fileUpload = React.createRef();
    }
    componentDidMount() {
        this._isMounted = true;
        
        const cart = this.props.cart;
        if(cart.length === 0){
            showMsg("Please add some items to the cart.",'info');
            this.props.history.push('/');
            return false;
        }
        /* this.props.fetchResturantTax().then(response => {
            this.setState({tax:this.props.objtax.tax});
            this.calculateTotal(cart);
        }); */
        this.calculateTotal(cart);
        this.props.fetchRestaurantProvince().then(response => {
            let restaurant_province = this.props.objRestaurantProvince.restaurant_province;
            let tax = 0;
            if(restaurant_province.toLowerCase() === 'ontario'){
                // alert('dssdds')
                if(parseFloat(this.state.subTotal) <= 4){
                    tax = 5; 
                }else if(parseFloat(this.state.subTotal) > 4){
                    tax = 13; 
                }
            }
            if(this._isMounted){
                this.setState({ tax,restaurant_province  },()=>{
                    this.calculateTotal(cart);
                });
            }
        });
        this.props.fetchResturantDeliveryFees().then(response => {
            
            if(this._isMounted){
                this.setState({deliveryFees:this.props.objRestaurantDeliveryFees.delivery_fees});
            }
            // console.log(this.props.objtax);
        });
        this.props.fetchResturantPhoneNumber().then(response => {
            if(this._isMounted){
                this.setState({restaurant_phone_number:this.props.objRestaurantPhoneNumber.restaurant_phone_number});
            }
            // console.log(this.props.objtax);
        });
        this.props.fetchResturantAddress().then(response => {
            if(this._isMounted){
                this.setState({origin:this.props.objRestaurantAddress.restaurant_address,restaurant_address:this.props.objRestaurantAddress.restaurant_address},()=>{
                    if(this.state.city !== '' && this.state.query !== '' && this.DistanceMatrixServiceG !== undefined){
                        this.calculateDistance(false);
                    }
                });
            }
        });
        this.props.fetchResturantStripeApiKeys().then(response => {
            if(this._isMounted){
                this.setState({restaurant_stripe_publishable_key:this.props.objRestaurantStripeApiKeys.restaurant_stripe_publishable_key});
            }
        });
        
        let delivery_type = this.props.match.params.delivery_type;
        let auth_type = this.props.match.params.auth_type;
        this.setState({delivery_type,auth_type});
        
        // console.log('i am in forward');
        let userData = this.props.user.userData;
        if(userData.isAuth){
            let address = userData.address;
            // console.log('address in componentDidMount')
            // console.log(address)
            if(!address){
                address = '';
            }
            if(this._isMounted){
                this.setState({
                        city: address,
                        query: address,
                },()=>{
                    if(this.DistanceMatrixServiceG !== undefined){
                        this.calculateDistance(false);
                    }
                });
            }
        }
        const orderSubmitFormValues = JSON.parse(localStorage.getItem('orderSubmitFormValues'));
        const homePageCustomerFormValues = JSON.parse(localStorage.getItem('homePageCustomerFormValues'));

        if(orderSubmitFormValues !== null && Object.keys(orderSubmitFormValues).length > 0){
            if(!userData.isAuth){
                if(orderSubmitFormValues.objCustomer){
                    const address = orderSubmitFormValues.objCustomer.address;
                    if(this._isMounted){
                        this.setState({
                            city: address,
                            query: address,
                        },()=>{
                            if(this.DistanceMatrixServiceG !== undefined){
                                this.calculateDistance(false);
                            }
                        });
                    }
                }
            }
        }else if(homePageCustomerFormValues !== null && Object.keys(homePageCustomerFormValues).length > 0){
            if(!userData.isAuth){
                if(delivery_type === 'delivery'){
                    const address = homePageCustomerFormValues.address;
                    if(this._isMounted){
                        this.setState({
                            city: address,
                            query: address,
                        },()=>{
                            if(this.DistanceMatrixServiceG !== undefined){
                                this.calculateDistance(false);
                            }
                        });
                    }
                }
            }
        }
        window.AddressDetails = this;
        // alert('i am mounted')
        // console.log('this.props.menuCategories')
        // console.log(this.props.menuCategories)
    }
    componentWillUnmount() {
        this._isMounted = false;
        if(this.stripeHandler) {
            this.stripeHandler.close();
        }
    }
    calculateTotal = (cartDetailData) => {
        // let total = 0;
        // cartDetail.forEach((item, index) => {
        //     let isShowIngredientsList = false;
        //     if (item.ingredients && item.ingredients.length > 0) {
        //         isShowIngredientsList = true;
        //         let ingredientTotal = 0;
        //         for (let i = 0; i < item.ingredients.length; i++) {
        //             const element = item.ingredients[i];
        //             if (element.ingredientType === 'extras_charged' && element.is_checked === true) {
        //                 ingredientTotal = parseFloat(parseFloat(ingredientTotal) + parseFloat(element.price)).toFixed(2);
        //             }
        //         }
        //         cartDetail[index].ingredientTotal = ingredientTotal;
        //     } else {
        //         let ingredientTotal = 0;
        //         cartDetail[index].ingredientTotal = parseFloat(ingredientTotal).toFixed(2);
        //     }
        //     if (item.selectedComboItems.length > 0) {
        //         for (let i = 0; i < item.selectedComboItems.length; i++) {
        //             const elementCombo = item.selectedComboItems[i];
        //             let isShowIngredientsListSub = false;
        //             let ingredientTotal = 0;
        //             for (let j = 0; j < elementCombo.ingredients.length; j++) {
        //                 const element = elementCombo.ingredients[j];
        //                 isShowIngredientsListSub = true;
        //                 if (element.ingredientType === 'extras_charged' && element.is_checked === true) {
        //                     ingredientTotal = parseFloat(parseFloat(ingredientTotal) + parseFloat(element.price)).toFixed(2);
        //                 }
        //             }
        //             cartDetail[index].selectedComboItems[i].ingredientTotal = ingredientTotal;
        //             cartDetail[index].selectedComboItems[i].isShowIngredientsList = isShowIngredientsListSub;
        //         }
        //     }
        //     total = parseFloat(parseFloat(total) + parseFloat(item.totalPrice)).toFixed(2);
        //     cartDetail[index].isShowIngredientsList = isShowIngredientsList;
        // });
        let {total,cartDetail} = calculateTotalHelper(cartDetailData);
        this.setState({
            subTotal : total,
            cartDetail
            // showTotal: true
        });
    }
    handleScriptLoad() {
        // alert('handleScriptLoad')
        // Declare Options For Autocomplete
        /*var options = {
          types: ['(cities)'],
        };*/
    
        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById('autocomplete'),
        //   options,
        );
        
    
        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);

        this.DistanceMatrixServiceG = new google.maps.DistanceMatrixService;
        this.calculateDistance(false);

    }
      
    handlePlaceSelect() {
    
        // Extract City From Address Object
        let addressObject = this.autocomplete.getPlace();
        let address = addressObject.address_components;
        // console.log('addressObject')
        // console.log(addressObject)
        // Check if address is valid
        if (address) {
          // Set State
            this.setState(
                {
                    city: address[0].long_name,
                    query: addressObject.formatted_address,
                },
                () => {
                    this.calculateDistance();
                });
        }
    }

    calculateDistance(isShowError = true){
        let origin =this.state.origin;
        let destination =this.state.query;
        this.setState({isCalculatingDeliveryFee:true});
       
        return new Promise((resolve, reject) => {
            this.DistanceMatrixServiceG.getDistanceMatrix({
                origins: [origin],
                destinations: [destination],
                travelMode: 'DRIVING',
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, (response, status) =>  {
                this.setState({isCalculatingDeliveryFee:false});
            // }, function(response, status) {
                // resolve({response,status});
                // console.log('dont go further');
                /* 0-5.9km- $6.00
                        6-9.9km-$7.00
                        10-14.9km- $9.00
                        Delibery over 15km start at $9 + $1/km */
                if (status !== 'OK') {
                    console.log('status in goo')
                    console.log('status in goo')
                    console.log('status in goo')
                    console.log(status)
                    // alert('Error was in calculateDistance: ' + status);
                    alert("Error in Google Maps Please try again or check your interent connection");
                    if(this._isMounted){
                        this.setState({isOrderSavingLoader:false});
                    }
                    // alert('Error was in calculateDistance: ' + status);
                } else {
                    // let originList = response.originAddresses;
                    // let destinationList = response.destinationAddresses;
                    // console.log('originList')
                    // console.log(originList)
                    // console.log('destinationList')
                    // console.log(destinationList)
                    // console.log('response.rows')
                    // console.log(response.rows)
                    if (response.rows[0].elements[0].status === "ZERO_RESULTS" ) {
                        console.log("Better get on a plane. There are no roads between "  + origin + " and " + destination)
                        if(isShowError){
                            alert("Better get on a plane. There are no roads between "  + origin + " and " + destination);
                        }
                        this.setState({isOrderSavingLoader:false});
                    }else if(response.rows[0].elements[0].status === "NOT_FOUND"){
                        console.log("Google Maps can not locate you please input a valid location"  + origin + " and " + destination);
                        if(isShowError){
                            alert("Google Maps can not locate you please input a valid location"  + origin + " and " + destination);
                        }
                        this.setState({isOrderSavingLoader:false});
                    } else {
                        // console.log('this.state.delivery_fees in calculateDistance')
                        // console.log('this.state.delivery_fees in calculateDistance')
                        // console.log('this.state.delivery_fees in calculateDistance')
                        // console.log('this.state.delivery_fees in calculateDistance')
                        // console.log(this.state.deliveryFees)
                        
                        let distance = response.rows[0].elements[0].distance;
                        // let duration = response.rows[0].elements[0].duration;
                        // console.log(response.rows[0].elements[0].distance);
                        let distance_in_kilo = distance.value / 1000; // the kilom
                        // let distance_in_mile = distance.value / 1609.34; // the mile
                        console.log('distance_in_kilo')
                        console.log(distance_in_kilo.toFixed(2))
                        distance_in_kilo = (distance_in_kilo).toFixed(2);
                        // distance_in_kilo = 1430.66;
                        // just for testing 
                        // distance_in_kilo = 20;
                        let delivery_amount = 0;
                        const delivery_fees = this.state.deliveryFees;
                        if(Object.keys(delivery_fees).length > 0){
                            delivery_amount = getDeliveryAmount(delivery_amount,delivery_fees,distance_in_kilo);
                        }
                        console.log('delivery_amount')
                        console.log(delivery_amount)
                        
                        // delivery_amount = 0;
                        // console.log()
                        if(isShowError){
                            let objIsValidDeliveryAmount = validateDeliveryAmount(delivery_amount,this.props.objRestaurantPhoneNumber)
                            if(objIsValidDeliveryAmount.error){
                                showMsg(objIsValidDeliveryAmount.msg,'error');
                                if(this._isMounted){
                                    this.setState({isOrderSavingLoader:false});
                                }
                                return false
                            }
                        }
                        if(this._isMounted){
                            this.setState({
                                delivery_amount,
                                distance_in_kilo,
                            })
                        }
                    }
                    resolve({response,status});
                }
            });
        });
    }
    inputAddressChangedHandler = (event) => {
        const updatedKeyword = event.target.value;
        // console.log('updatedKeyword in inputAddressChangedHandler')
        // console.log(updatedKeyword)

        this.setState(
            {
            //   city: address[0].long_name,
              query: updatedKeyword,
            }
        );
        // May be call for search result
    }

    /* handleStripeScriptLoad(){
        this.stripeHandler = window.StripeCheckout.configure({
            key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            // key: 'pk_test_UaA35cqi5DTDnyyihMyh9DZE00BFSogLSQ',process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
            image: oldLogoPng,
            // image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
            
            locale: 'auto',
            
            // token: function(token) {
            token: (token) => {
                // this.setState({isOrderSavingLoader:true});
                // this.submitOrderEnd(token)
                console.log('token in submit')
                console.log(token)
                console.log('this.state.formData')
                console.log(this.state)
                console.log(this.state.formData)
                this.setState({isOrderSavingLoader:true});
                const cart = this.props.cart;
                const user = this.props.user;
                let values = this.state.formData;
                values.address = this.state.query;
                values.cart = cart;
                values.user = user.userData;
                values.stripe_token = token.id;
                values.total_amount = this.state.total;
                values.delivery_amount = this.state.delivery_amount;
                values.distance_in_kilo = this.state.distance_in_kilo;
                values.taxAmount = this.state.taxAmount;
                values.total_amount_after_tax_delivery_charges = this.state.totalAmountAfterTax;
                values.drp_default_time = 15;
                let time_picker_time = addTimeToCurrentDateTime(values.drp_default_time)
                values.time_picker_time = time_picker_time;
                values.order_completion_datetime = time_picker_time;
                values.discount_amount = 0.00;
                values.discount_percentage = 0;
                values.discount_amount_entered = 0;
                // values.order_completion_datetime = addTimeToCurrentDateTime(values.drp_default_time);
                console.log('time_picker_time')
                console.log('time_picker_time')
                console.log(time_picker_time)
                values.customer_given_amount = "";
                values.timeSelectionRadioBox = "have_ready_in";
                let objCustomer = {};
                objCustomer.address = values.address;
                objCustomer.delivery_amount = values.delivery_amount;
                objCustomer.distance_in_kilo = values.distance_in_kilo;
                objCustomer.drp_default_time = values.drp_default_time;
                objCustomer.fullname = values.fullname;
                objCustomer.phone_no = values.phone_no;
                objCustomer.notes = values.notes;
                objCustomer.query = this.state.query;
                objCustomer.timeSelectionRadioBox = "have_ready_in";
                objCustomer.time_picker_time = time_picker_time;
                values.objCustomer = objCustomer;
                console.log('values')
                console.log(values)
                this.props.submitCreateOrder(values).then(response => {
                    // console.log('this.props.order');
                    // console.log(this.props.order);
                    // if(orderSavedSuccess)
                    this.setState({isOrderSavingLoader:false});
                    let orderResponse = this.props.order.orderSavedSuccess;
                    if(orderResponse.error){
                        showMsg(orderResponse.msg,'error');
                    }else{
                        // alert('success')
                        const push_notification_subscription_token = localStorage.getItem("push_notification_subscription_token");
                        socket.emit("putOrder", {objOrder:orderResponse.objOrder,push_notification_subscription_token});
                        console.log('{objOrder:orderResponse.objOrder,push_notification_subscription_token}')
                        console.log('{objOrder:orderResponse.objOrder,push_notification_subscription_token}')
                        console.log('{objOrder:orderResponse.objOrder,push_notification_subscription_token}')
                        console.log({objOrder:orderResponse.objOrder,push_notification_subscription_token})
                        // console.log('orderResponse.objOrder')
                        // console.log(orderResponse.objOrder)
                        showMsg(orderResponse.msg,'success');
                        // console.log('i have putOrder');
                        // console.log('orderResponse.order_id')
                        // console.log(orderResponse.order_id)
                        this.props.clearCart();
                        // alert('sss')
                        this.props.history.push(`/order-details/${orderResponse.order_id}`);
                    }
                });
              // You can access the token ID with `token.id`.
              // Get the token ID to your server-side code for use.
            }
        });
    } */
   

    onSubmitForm = async (values) => {
        console.log('values in submit')
        console.log(values)
        values = {delivery_type:this.state.delivery_type,...values};
        
        if(this.state.delivery_type === 'delivery'){
            if(this.state.query === ''){
                showMsg("Please enter your address.",'error');
                return false;
            }
        }

        this.setState({isOrderSavingLoader:true});
        let isShowDeliveryError = false;
        if(this.state.delivery_type === 'delivery'){
            isShowDeliveryError = true;
        }
        // let {response,status} = await this.calculateDistance(isShowDeliveryError);
        this.setState({isOrderSavingLoader:false});
        
        let delivery_amount = 0.00;
        let distance_in_kilo = 0;
        if(this.state.delivery_type === 'delivery'){
            let {response,status} = await this.calculateDistance(isShowDeliveryError);
            if (status !== 'OK') {
                alert('Please try again their was a error occured from Google Maps Api: ' + status);
                return false;
                // alert('Error was in calculateDistance: ' + status);
            } else {
                if (response.rows[0].elements[0].status === "ZERO_RESULTS" ) {
                    alert("Please choose the address with in the city. There are no roads between "  + this.state.origin + " and " + this.state.query);
                    this.setState({isOrderSavingLoader:false});
                    return false;
                }else if(response.rows[0].elements[0].status === "NOT_FOUND"){
                    alert("Google Maps can not locate you please input a valid location "  + this.state.origin + " and " + this.state.query);
                    this.setState({isOrderSavingLoader:false});
                    return false;
                } else {
                    let distance = response.rows[0].elements[0].distance;
                    // let duration = response.rows[0].elements[0].duration;
                    distance_in_kilo = Math.ceil(distance.value / 1000); // the kilom
                    // let distance_in_mile = distance.value / 1609.34; // the mile
                    // console.log(distance_in_kilo.toFixed(2))
                    distance_in_kilo = (distance_in_kilo).toFixed(2)
                    
                    delivery_amount = 0;
                    const delivery_fees = this.state.deliveryFees;
                    if(Object.keys(delivery_fees).length > 0){
                        delivery_amount = getDeliveryAmount(delivery_amount,delivery_fees,distance_in_kilo);
                    }
                    /* if(distance_in_kilo > 0 && distance_in_kilo<=5.9){
                        delivery_amount = 6.00;
                    }else if(distance_in_kilo >= 6 && distance_in_kilo<=9.9){
                        delivery_amount = 7.00;
                    }else if(distance_in_kilo >= 10 && distance_in_kilo<=14.9){
                        delivery_amount = 9.00;
                    }else if(distance_in_kilo >= 15){
                        let calculate_over_distance = parseFloat(parseFloat(distance_in_kilo) - 15).toFixed(2);
                        delivery_amount = parseFloat(9.00+parseFloat(calculate_over_distance)).toFixed(2);
                    }
                    console.log('delivery_amount')
                    console.log(delivery_amount) */
                    let objIsValidDeliveryAmount = validateDeliveryAmount(delivery_amount,this.props.objRestaurantPhoneNumber)
                    if(objIsValidDeliveryAmount.error){
                        showMsg(objIsValidDeliveryAmount.msg,'error');
                        this.setState({isOrderSavingLoader:false});
                        return false;
                    }
                    this.setState({
                        delivery_amount,
                        distance_in_kilo,
                    })
                }
                
            }
        }
      
        console.log('values in submit')
        console.log('values in submit')
        console.log('values in submit')
        console.log(values)
        
        if(this.state.delivery_type === 'delivery' && this.state.query === ''){
            showMsg("Please fill out the address.",'error');
        }else{
            // this.setState({isOrderSavingLoader:true,formData:values});
            this.setState({formData:values}, () => {
                if(this.state.delivery_type === 'delivery'){
                    let objIsValidDeliveryAmount = validateDeliveryAmount(this.state.delivery_amount,this.props.objRestaurantPhoneNumber)
                    if(objIsValidDeliveryAmount.error){
                        showMsg(objIsValidDeliveryAmount.msg,'error');
                        this.setState({isOrderSavingLoader:false});
                        return false;
                    }
                }
                let subTotal = 0;
                /* if(this.state.delivery_type === 'delivery' && values.driver_tip){
                    total = parseFloat(parseFloat(this.state.total) + parseFloat(delivery_amount) + parseFloat(values.driver_tip)).toFixed(2)    
                }else{
                    total = parseFloat(parseFloat(this.state.total) + parseFloat(delivery_amount)).toFixed(2)
                } */
                // alert(delivery_amount)
                // alert(this.state.delivery_amount)
                // total = parseFloat(parseFloat(this.state.total) + parseFloat(delivery_amount)).toFixed(2)
                subTotal = this.state.subTotal;
                // console.log('total after delivery and distance_in_kilo')
                // console.log(total)
                let tax = parseFloat(this.state.tax/100).toFixed(2);
                let taxAmount = ((subTotal) * (tax)).toFixed(2);
                // console.log('taxAmount')
                // console.log(taxAmount)
                let total = (parseFloat(subTotal) + parseFloat(taxAmount)).toFixed(2);
                this.setState({
                    taxAmount,
                    total
                },() => {
                    // console.log('this.state.query before the stripe')
                    // console.log(this.state.query)
                    /* this.stripeHandler.open({
                        name: 'Jubzi',
                        description: 'Complete your order',
                        // panelLabel: 'Pay Now',
                        allowRememberMe: true,
                        currency: 'USD',
                        //USD,EUR,AUD,GBP
                        amount: total* 100
                    }); */
        
                    this.setState({isOrderSavingLoader:true});
                    const cart = this.props.cart;
                    const user = this.props.user;
                    let values = this.state.formData;
                    values.address = this.state.query;
                    values.cart = cart;
                    values.auth_type = this.state.auth_type;
                    values.user = user.userData;
                    values.fullname = `${values.firstname} ${values.lastname}`; 
                    // values.stripe_token = token.id;
                    values.subTotal = this.state.subTotal;
                    values.total = this.state.total;
                    values.delivery_amount = this.state.delivery_amount;
                    values.distance_in_kilo = this.state.distance_in_kilo;
                    values.taxAmount = this.state.taxAmount;
                    // values.totalAmount = this.state.totalAmount;
                    values.drp_default_time = 15;
                    let time_picker_time = addTimeToCurrentDateTime(values.drp_default_time)
                    values.time_picker_time = time_picker_time;
                    values.order_completion_datetime = time_picker_time;
                    values.discount_amount = 0.00;
                    values.discount_percentage = 0;
                    values.discount_amount_entered = 0;
                    // values.order_completion_datetime = addTimeToCurrentDateTime(values.drp_default_time);
                    console.log('time_picker_time')
                    console.log('time_picker_time')
                    console.log(time_picker_time)
                    values.customer_given_amount = "";
                    values.timeSelectionRadioBox = "have_ready_in";
                    let objCustomer = {};
                    objCustomer.address = values.address;
                    objCustomer.appartment_unit_number = values.appartment_unit_number;
                    objCustomer.delivery_amount = values.delivery_amount;
                    objCustomer.distance_in_kilo = values.distance_in_kilo;
                    objCustomer.drp_default_time = values.drp_default_time;
                    objCustomer.fullname = values.fullname;
                    objCustomer.phone_no = values.phone_no;
                    objCustomer.query = this.state.query;
                    objCustomer.timeSelectionRadioBox = "have_ready_in";
                    objCustomer.time_picker_time = time_picker_time;
                    values.objCustomer = objCustomer;
                    values.push_notification_subscription_token = localStorage.getItem("push_notification_subscription_token");
                    console.log('values after submit')
                    console.log(values)
                    // alert(this.state.delivery_amount)
                    // alert(values.total_amount_after_tax_delivery_charges)
                    localStorage.setItem('orderSubmitFormValues',JSON.stringify(values));
                    this.props.history.push(`/order-review`);
                    

                })
            });
            
            
            
        }
        
        
    }
    render() {

        // const objtax = this.props.objtax;
        const objRestaurantProvince = this.props.objRestaurantProvince;
        if (objRestaurantProvince === undefined || (!objRestaurantProvince)) {
        // if (objtax === undefined || (!objtax)) {
            return (
                <LoadingSpinner />
            )
        }else{
            // console.log('this.props.user ina dd')
            // console.log(this.props.user);
            let initialValues = {'drpCity': 'Windsor Ontario'};
            let userData = this.props.user.userData;
            console.log('userData')
            console.log('userData')
            console.log(userData)
            if(userData.isAuth){
                initialValues.email = userData.email;
                initialValues.fullname = userData.fullname;
                initialValues.firstname = userData.name.first;
                initialValues.lastname = userData.name.last;
                initialValues.phone_no = userData.phone_no;
                initialValues.address = this.state.query;
                initialValues.appartment_unit_number = userData.appartment_unit_number;
                initialValues.security_question_answer = userData.security_question_answer;

            }else{
                
                initialValues.email = '';
                initialValues.fullname = '';
                initialValues.firstname = '';
                initialValues.lastname = '';
                initialValues.phone_no = '';
                initialValues.address = '';
                initialValues.appartment_unit_number = '';
                initialValues.security_question_answer ='';
            }
            initialValues.password = '';
            initialValues.confirmPassword = '';
            initialValues.notes = '';

            const homePageCustomerFormValues = JSON.parse(localStorage.getItem('homePageCustomerFormValues'));
            if(homePageCustomerFormValues !== null && Object.keys(homePageCustomerFormValues).length > 0){
                if(!userData.isAuth){
                    initialValues.firstname = homePageCustomerFormValues.fullname;
                    initialValues.fullname = homePageCustomerFormValues.fullname;
                    initialValues.phone_no = homePageCustomerFormValues.phone_no;
                    if(homePageCustomerFormValues.delivery_type === 'delivery'){
                        initialValues.address = homePageCustomerFormValues.address;
                        initialValues.appartment_unit_number = homePageCustomerFormValues.appartment_unit_number;
                    }else{
                        initialValues.address = '';
                        initialValues.appartment_unit_number = '';
                    }
                }
            }

            const orderSubmitFormValues = JSON.parse(localStorage.getItem('orderSubmitFormValues'));
            if(!userData.isAuth && orderSubmitFormValues !== null && Object.keys(orderSubmitFormValues).length > 0){
                if(userData.isAuth){
                    initialValues.email = userData.email;
                }else{
                    initialValues.email = orderSubmitFormValues.email;
                }
                initialValues.fullname = orderSubmitFormValues.fullname;
                initialValues.firstname = orderSubmitFormValues.firstname;
                initialValues.lastname = orderSubmitFormValues.lastname;
                initialValues.phone_no = orderSubmitFormValues.phone_no;
                initialValues.address = this.state.query;
                initialValues.appartment_unit_number = orderSubmitFormValues.appartment_unit_number;
                initialValues.security_question_answer = orderSubmitFormValues.security_question_answer;
                initialValues.notes = orderSubmitFormValues.notes;
            }
            
            
            return (
                    <Fragment>
                        <Script
                            url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
                            onLoad={this.handleScriptLoad}
                        />
                       
                        
                        <header className="container inner-header">
                            <div className="row">
                                <div className="col-12 mb20">
                                    <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={this.props.history.goBack} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        this.state.delivery_type === 'delivery' ?
                                        <h4>Enter your information</h4>
                                        :null
                                    }
                                    {
                                        this.state.delivery_type === 'pickup' ?
                                        <h4>Enter your information</h4>
                                        :null
                                    }
                                </div>
                            </div>
                        </header>
                        <div className="inner-body addressdetails-main">
                            <div className="container my-2">
                                <div className="row bg-grey border-round product-bill p-0">
                                    <div className="col-12">
                                        {/* <input placeholder="Address" type="text" className="form-control form-control2"  id="autocomplete"  value={this.state.query}   /> */}
                                        {/* <input placeholder="Address" type="text" className="form-control form-control2"  id="autocomplete"  value={this.state.query}  onChange={(event)=>this.inputAddressChangedHandler(event)} /> */}
                                    </div>
                                </div>
                                {/* <StripeCheckout
                                    label="Go Premium" //Component button text
                                    name="Business LLC" //Modal Header
                                    description="Upgrade to a premium account today."
                                    panelLabel="Go Premium" //Submit button in modal
                                    amount={999} //Amount in cents $9.99
                                    token={this.onToken}
                                    stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                                    // stripeKey={'pk_test_UaA35cqi5DTDnyyihMyh9DZE00BFSogLSQ'}process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
                                    image="https://www.vidhub.co" //Pop-in header image
                                    billingAddress={false}
                                > 
                                    <Button  >
                                        Click Me!
                                    </Button>
                                </StripeCheckout> */}
                                {/* <StripeProvider apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx">
                                    <Elements> */}
                                        <AddressDetailForm 
                                            // onSubmit={this.submit} 
                                            initialValues={initialValues}
                                            // initialValues={{email:'ussss@ds.com'}}
                                            inputAddressChangedHandler={this.inputAddressChangedHandler}
                                            query={this.state.query}
                                            onToken={this.onToken}
                                            userData={userData}
                                            isOrderSavingLoader={this.state.isOrderSavingLoader}
                                            delivery_type={this.state.delivery_type}
                                            delivery_amount={this.state.delivery_amount}
                                            distance_in_kilo={this.state.distance_in_kilo}
                                            isCalculatingDeliveryFee={this.state.isCalculatingDeliveryFee}
                                            auth_type={this.state.auth_type}
                                            onSubmitForm={this.onSubmitForm}
                                        />
                                   {/*  </Elements>
                                </StripeProvider> */}
                                
                            </div>
                        </div>
                    </Fragment>
                    
            
            )
        }
    }
}


function mapStateToProps(state) {
    return { 
        cart : state.cart,
        order : state.order,
        objtax : state.settings.objtax,
        objRestaurantDeliveryFees : state.settings.objRestaurantDeliveryFees,
        objRestaurantPhoneNumber : state.settings.objRestaurantPhoneNumber,
        objRestaurantAddress : state.settings.objRestaurantAddress,
        objRestaurantStripeApiKeys : state.settings.objRestaurantStripeApiKeys,
        objRestaurantProvince: state.settings.objRestaurantProvince,
    };
}
// export default AddressDetails
export default connect(mapStateToProps, { fetchResturantTax, fetchRestaurantProvince, fetchResturantDeliveryFees,fetchResturantPhoneNumber,fetchResturantAddress,fetchResturantStripeApiKeys,submitCreateOrder,clearCart })(withRouter(AddressDetails));

import React,{Fragment} from 'react';
import TimeKeeper from 'react-timekeeper';
import { Formik, Field, Form, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
import moment from 'moment';
let PickupForm = (props)  => {
    
    
    const { initialValues, handelOnChangeTimeKeeper } = props;

    
    const validate = (values) => {
        let errors = {}
        if (!values.timeSelectionRadioBox) {
            errors.timeSelectionRadioBox = 'This field is required.';
        }else{
            if(values.timeSelectionRadioBox === "have_ready_in"){
                if(!values.drp_default_time){
                    errors.drp_default_time = 'This field is required.';
                }
            }
        }
        return errors
    }
    const myDefaultTimeChange = (drp_default_time) => {
        // console.log('myDefaultTimeChange')
        // console.log('myDefaultTimeChange')
        // console.log(drp_default_time)
        props.handleOnChangeDrfDefaultTime(drp_default_time);
        // this.setState({ suggestions: [] });
    };
    const now = moment();
    return (
        <Fragment>
            <Formik
                // enableReinitialize={false}
                ref={props.pickupForm}
                enableReinitialize
                initialValues={initialValues}
                validate={validate}
                onSubmit={props.onSubmitPickupForm}
                render={({ values,errors, status, touched,setFieldValue }) => (
                    <Form autoComplete="off">
                        <div className="row">
                            <div className="col-lg-6 col-md-5 col-sm-4 col-xs-12">
                                <div className="form-group">
                                    <div className="form-check form-check-inline">
                                        <Field
                                            name="timeSelectionRadioBox"
                                            // component="input"
                                            type="radio"
                                            value="have_ready_in"
                                            checked={values.timeSelectionRadioBox === "have_ready_in"} 
                                        />
                                        <ErrorMessage name="timeSelectionRadioBox" component="div" className="invalid-feedback invalid-feedback-error" />
                                        <label className="form-check-label" >Have Ready In</label>
                                    </div>
                                    <div className="form-group mt15">
                                        <Field 
                                            name="drp_default_time"  
                                            className="form-control"
                                            component="select" 
                                            onChange={e => {
                                                setFieldValue("drp_default_time", e.target.value);
                                                myDefaultTimeChange(e.target.value);
                                            }}
                                            // onChange={e => setFieldValue('drp_default_time', e)}
                                        >
                                            <option value="">Select Time</option>
                                            {/* <option value="5">5 Minutes</option> */}
                                            {/* <option value="10">10 Minutes</option> */}
                                            <option value="15">15 Minutes</option>
                                            <option value="30">30 Minutes</option>
                                            <option value="45">45 Minutes</option>
                                        </Field>
                                        <ErrorMessage name="drp_default_time" component="div" className="invalid-feedback invalid-feedback-error" />
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-7 col-sm-8 col-xs-12">
                                <div className="form-group">
                                    <div className="form-check form-check-inline">
                                        <Field
                                            name="timeSelectionRadioBox"
                                            // component="input"
                                            type="radio"
                                            value="other_time"
                                            checked={values.timeSelectionRadioBox === "other_time"} 
                                        />
                                        <ErrorMessage name="timeSelectionRadioBox" component="div" className="invalid-feedback invalid-feedback-error" />
                                        <label className="form-check-label" >Other</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <TimeKeeper
                                        time={ props.time_picker_time === ''  ?  {hour: parseInt(now.format('h')),minute: parseInt(now.minutes()),meridiem: 'pm'} : {hour: props.time_picker_time.hours(),minute: props.time_picker_time.minutes()} }
                                        // time={ {meridiem: 'pm'}}
                                        // time={ {hour: now.hours(),minute: now.minutes(),meridiem: 'pm'}}
                                        // time={ {hour: 4,minute: 55,meridiem: 'pm'}}
                                        // time={false}
                                        onChange={(data) => {
                                            handelOnChangeTimeKeeper(data);
                                            setFieldValue("timeSelectionRadioBox", "other_time");
                                            props.handleOnChangetimeSelectionRadioBox("other_time")
                                        }} 
                                    />
                                </div>
                            </div> 
                        </div>
                        
                    </Form>
                )}
            />
            
        </Fragment>
    );
}






  export default PickupForm
import axios from 'axios';
import {
    GET_MENU_ITEMS_BY_CATEGORY_ID,
    FETCH_MENU_ITEM_BY_ID,
    FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID,
    FETCH_MULTIPLE_MENU_ITEM_BY_ID,
    FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID,
    FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS,
    CLEAR_MENU_ITEMS,
} from './types';
import { BASE_URL } from '../components/utils/misc';
// import { PRODUCT_SERVER } from '../components/utils/misc';

export const getMenuItemsByCategoryId = (skip, limit,menuCategoryId, previousState = []) => async dispatch => {
    const data = {
        limit,
        skip,
        menuCategoryId
    }
    const response = await axios.post(`${BASE_URL}/api/web/get-menu-items-by-category-id`,data);
    let newState = [
        ...previousState,
        ...response.data.MenuItems
    ];
    let res = {
        total_menu_items: response.data.total_menu_items,
        MenuItems: newState
    }
    dispatch({ type: GET_MENU_ITEMS_BY_CATEGORY_ID, payload: res });
};


export function getMenuItemsByCategoryId_promise(skip, limit,menuCategoryId, previousState = []){
    const data = {
        limit,
        skip,
        menuCategoryId
    }

    // const request = axios.post(`${PRODUCT_SERVER}/shop`,data)
    const request = axios.post(`${BASE_URL}/api/web/get-menu-items-by-category-id`,data)
                .then(response => {
                    let newState = [
                        ...previousState,
                        ...response.data.MenuItems
                    ];
                    return {
                        total_menu_items: response.data.total_menu_items,
                        MenuItems: newState
                    }
                });

    return {
        type: GET_MENU_ITEMS_BY_CATEGORY_ID,
        payload: request
    }

}



export const fetchMenuItemById = (menu_item_id) => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/fetch-menu-item-by-id?id=${menu_item_id}`);
    
    dispatch({ type: FETCH_MENU_ITEM_BY_ID, payload: res.data });
};
export const fetchMenuItemByIdWithIngredients = (menu_item_id) => async dispatch => {
    // console.log('fetchMenuItemByIdWithIngredients')
    const res = await axios.get(`${BASE_URL}/api/fetch-menu-item-by-id-with-ingredients?id=${menu_item_id}`);
    dispatch({ type: FETCH_MENU_ITEM_BY_ID_WITH_INGREDIENTS, payload: res.data });
};
export const fetchMenuItemCombosByMenuItemId = (menu_item_id) => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/fetch-menu-item-combos-by-menuitemid?id=${menu_item_id}`);
    // for (let index = 0; index < array.length; index++) {
    //     const element = array[index];
        
    // }
    // console.log('res.data in fetchMenuItemCombosByMenuItemId')
    // console.log(res.data)
    // for (let index = 0; index < res.data.MenuItemCombos.length; index++) {
    //     // const element = res.data.MenuItemCombos[index];
    //     res.data.MenuItemCombos[index].isChecked = false;
    // }
    dispatch({ type: FETCH_MENU_ITEM_COMBOS_BY_MENU_ITEM_ID, payload: res.data });
};

export const fetchMultipleMenuItemsByIds = (selectedCstateomboItems) => async dispatch => {
    // console.log('selectedCstateomboItems in meny action')
    // console.log(selectedCstateomboItems)
    const data = {
        list:selectedCstateomboItems
    }
    const res = await axios.post(`${BASE_URL}/api/fetch-multiple-menu-items`,data);
    
    dispatch({ type: FETCH_MULTIPLE_MENU_ITEM_BY_ID, payload: res.data });
};

export const fetchMultipleMenuItemsIngredientsByIds = (selectedCstateomboItems) => async dispatch => {
    const data = {
        list:selectedCstateomboItems
    }
    const res = await axios.post(`${BASE_URL}/api/fetch-multiple-menu-item-ingredients`,data);
    
    dispatch({ type: FETCH_MULTIPLE_MENU_ITEM_INGREDIENTS_BY_ID, payload: res.data });
};

export const clearMenuItems = () => async dispatch => {
    dispatch({ type: CLEAR_MENU_ITEMS, payload: '' });
};
export function countOrderItems(Order) {
    let count = 0;
    Order.forEach((item, index) => {
        count = count + 1;
        count = count + item.selectedComboItems.length;
    });
    return count;
}

export function calculateTotalHelper(cartDetail) {
    let total = 0;
    cartDetail.forEach((item, index) => {
        let isShowIngredientsList = false;
        if (item.ingredients && item.ingredients.length > 0) {
            isShowIngredientsList = true;
            let ingredientTotal = 0;
            for (let i = 0; i < item.ingredients.length; i++) {
                const element = item.ingredients[i];
                if (element.ingredientType === 'extras_charged' && element.is_checked === true) {
                    ingredientTotal = parseFloat(parseFloat(ingredientTotal) + parseFloat(element.price)).toFixed(2);
                }
            }
            cartDetail[index].ingredientTotal = ingredientTotal;
        } else {
            let ingredientTotal = 0;
            cartDetail[index].ingredientTotal = parseFloat(ingredientTotal).toFixed(2);
        }
        if (item.selectedComboItems.length > 0) {
            for (let i = 0; i < item.selectedComboItems.length; i++) {
                const elementCombo = item.selectedComboItems[i];
                let isShowIngredientsListSub = false;
                let ingredientTotal = 0;
                for (let j = 0; j < elementCombo.ingredients.length; j++) {
                    const element = elementCombo.ingredients[j];
                    isShowIngredientsListSub = true;
                    if (element.ingredientType === 'extras_charged' && element.is_checked === true) {
                        ingredientTotal = parseFloat(parseFloat(ingredientTotal) + parseFloat(element.price)).toFixed(2);
                    }
                }
                cartDetail[index].selectedComboItems[i].ingredientTotal = ingredientTotal;
                cartDetail[index].selectedComboItems[i].isShowIngredientsList = isShowIngredientsListSub;
            }
        }
        total = parseFloat(parseFloat(total) + parseFloat(item.totalPrice)).toFixed(2);
        cartDetail[index].isShowIngredientsList = isShowIngredientsList;
    });
    return {total,cartDetail};
}

export function calculateTotalIncludingFee(total,delivery_amount,card_processing_fee,delivery_type,delivery_payment_method) {
    let _total
    if(card_processing_fee === false){
        if(delivery_type === 'delivery'){
            _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount)).toFixed(2);
        }else if(delivery_type === 'pickup'){
            _total = parseFloat(total).toFixed(2);
        }
    }else if(delivery_type === 'delivery'){
        if(delivery_payment_method === 'credit'){
            _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount) + parseFloat(card_processing_fee)).toFixed(2);
        }else if(delivery_payment_method === 'cash'){
            _total = parseFloat(parseFloat(total) + parseFloat(delivery_amount)).toFixed(2);
        }
    }else{
        _total = parseFloat(total).toFixed(2);
    }
    return _total;
}

export function calculateFinalTotalOfOrder(objOrder,delivery_payment_method,card_processing_fee) {
    let _total = 0;
    if(objOrder.delivery_type === 'delivery' && delivery_payment_method === 'credit'){
        _total = calculateTotalIncludingFee(objOrder.total,objOrder.delivery_amount,card_processing_fee,objOrder.delivery_type,delivery_payment_method);
    }else if(objOrder.delivery_type === 'delivery'){
        _total = parseFloat(parseFloat(objOrder.total) + parseFloat(objOrder.delivery_amount)).toFixed(2);
    }else if(objOrder.delivery_type === 'pickup'){
        _total = objOrder.total;
    }
    return parseFloat(_total).toFixed(2);
}


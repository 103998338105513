import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './authReducer';
import surveysReducer from './surveysReducer';
import menuCategoryReducer from './menuCategoryReducer';
import user from './user_reducer';
import products from './products_reducer';
import site from './site_reducer';
import cart from './cart_reducer';
import settings from './settings_reducer';
import MenuRducer from './menu_reducer';
import menuItemReducer from './menuItemReducer';
import menuItemWithIngredientReducer from './menuItemWithIngredientReducer';
import orderReducer from './order_reducer';

// These keys auth , form and surverys will live inside our state object
export default combineReducers({
  auth: authReducer,
  form: reduxForm,
  surveys: surveysReducer,
  menuCategories: menuCategoryReducer,
  user,
  products,
  MenuItems:MenuRducer,
  menuItem:menuItemReducer,
  menuItemWithIngredient:menuItemWithIngredientReducer,
  site,
  cart,
  settings,
  order:orderReducer,
});

import axios from 'axios';
import { FETCH_USER, FETCH_SURVEYS,AUTH_USER, AUTH_ERROR,FETCH_MENU_CATEGORIES } from './types';
import { BASE_URL } from '../components/utils/misc';
export const signup = (formProps, callback) => async dispatch => {
  try {
    const response = await axios.post(
      'http://localhost:3090/signup',
      formProps
    );

    dispatch({ type: AUTH_USER, payload: response.data.token });
    localStorage.setItem('token', response.data.token);
    callback();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: 'Email in use' });
  }
};

export const signin = (formProps, callback) => async dispatch => {
  try {
    const response = await axios.post(
      'http://localhost:3090/signin',
      formProps
    );

    dispatch({ type: AUTH_USER, payload: response.data.token });
    localStorage.setItem('token', response.data.token);
    callback();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: 'Invalid login credentials' });
  }
};

export const signout = () => {
  localStorage.removeItem('token');

  return {
    type: AUTH_USER,
    payload: ''
  };
};


export const fetchUser = () => async dispatch => {
  const res = await axios.get(`${BASE_URL}/api/current_user`);

  dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleToken = token => async dispatch => {
  const res = await axios.post(`${BASE_URL}/api/stripe`, token);

  dispatch({ type: FETCH_USER, payload: res.data });
};

export const submitSurvey = (values, history) => async dispatch => {
  const res = await axios.post(`${BASE_URL}/api/surveys`, values);

  history.push('/surveys');
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchSurveys = () => async dispatch => {
  const res = await axios.get(`${BASE_URL}/api/surveys`);

  dispatch({ type: FETCH_SURVEYS, payload: res.data });
};

export const fetchMenuCatgories = () => async dispatch => {
  const res = await axios.get(`${BASE_URL}/api/fetch-menu-categories`);
  
  dispatch({ type: FETCH_MENU_CATEGORIES, payload: res.data });
};



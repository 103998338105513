import axios from 'axios';
import {
    FETCH_RESTURANT_TAX,
    FETCH_RESTURANT_DELIVERY_AVAILABILITY,
    FETCH_RESTURANT_DELIVERY_FEES,
    CHECK_IF_RESTAURANT_IS_OPEN,
    FETCH_RESTURANT_PHONE_NUMBER,
    FETCH_RESTURANT_ADDRESS,
    FETCH_RESTAURANT_LOGO,
    FETCH_RESTAURANT_STRIPE_API_KEYS,
    FETCH_RESTAURANT_PROVINCE,
    FETCH_RESTAURANT_CARD_PROCESSING_FEE,
} from './types';
import { BASE_URL } from '../components/utils/misc';




export const fetchResturantTax = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/fetch_resturant_tax`);
    // console.log('res in tax')
    // console.log(res)
    dispatch({ type: FETCH_RESTURANT_TAX, payload: res.data });
};

export const fetchRestaurantProvince = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-province`);
    dispatch({ type: FETCH_RESTAURANT_PROVINCE, payload: res.data });
};

export const fetchRestaurantCardProcessingFee = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-card-processing-fee`);
    dispatch({ type: FETCH_RESTAURANT_CARD_PROCESSING_FEE, payload: res.data });
};

export const fetchResturantPhoneNumber = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-resturant-phone-number`);
    dispatch({ type: FETCH_RESTURANT_PHONE_NUMBER, payload: res.data });
    return Promise.resolve(res);
};

export const fetchResturantAddress = () =>  async (dispatch) => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-resturant-address`);
    // console.log('res in tax')
    // console.log(res)
    dispatch({ type: FETCH_RESTURANT_ADDRESS, payload: res.data });
    // return dispatch({ type: FETCH_RESTURANT_ADDRESS, payload: res.data });
    return Promise.resolve(res);
    // return res;
};
// export const fetchResturantAddress = () => {
//     return (dispatch, getState) => {
//         return  axios.get(`${BASE_URL}/api/settings/fetch-resturant-address`).then((response) => { 
//             dispatch({ type: FETCH_RESTURANT_ADDRESS, payload: response.data });
//             return response;
//         })
//     }
// }

export const fetchResturantStripeApiKeys = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-resturant-stripe-api-keys`);
    // console.log('res in tax')
    // console.log(res)
    dispatch({ type: FETCH_RESTAURANT_STRIPE_API_KEYS, payload: res.data });
};

export const fetchResturantLogo = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-logo`);
    // console.log('res in tax')
    // console.log(res)
    dispatch({ type: FETCH_RESTAURANT_LOGO, payload: res.data });
};

export const fetchResturantDeliveryFees = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-restaurant-delivery-fee`);
    // console.log('res in tax')
    // console.log(res)
    dispatch({ type: FETCH_RESTURANT_DELIVERY_FEES, payload: res.data });
};

export const fetchResturantDeliveryAvailability = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/settings/fetch-resturant-delivery-availability`);
    // console.log('res in tax')
    // console.log(res)
    dispatch({ type: FETCH_RESTURANT_DELIVERY_AVAILABILITY, payload: res.data });
    return Promise.resolve(res);
};

export const checkIfRestaurantIsOpen = () => async dispatch => {
    const data = {
        currentDatetime:new Date()
    }
    const res = await axios.post(`${BASE_URL}/api/check-if-restaurant-is-open`,data);
    
    dispatch({ type: CHECK_IF_RESTAURANT_IS_OPEN, payload: res.data });
};
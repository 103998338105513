import React from 'react';
import trashRedPng from '../../../common/images/icons/trash-red.png';
import UserCartItemSubBlock from './UserCartItemSubBlock.js' 
import RenderIngredients from './RenderIngredients';
// import { Link } from 'react-router-dom';
const UserCartItemBlock = ({products,removeItem,removeSubItemFromCart}) => {

    /* const renderCartImage = (images) => {
        if(images.length > 0){
            return images[0].url
        } else {
            return '/images/image_not_availble.png'
        }
    } */

    /* const renderIngredients = (ingredients)  => {
       
        if(ingredients.length > 0){
            let rowLen =ingredients.length;
            // return images[0].url
            let ret = ingredients.map((ingredient,index) => {
                if (rowLen === index + 1) {
                    // last one
                    return <span key={index}> {ingredient.name} </span>;
                } else {
                    // not last one
                    return <span key={index}> {ingredient.name} , </span>;
                }
                
            })
            return ret;
            
        } else {
            // return '/images/image_not_availble.png'
            return null;
        }
    } */


    const renderItems = () => (
        products ?
            products.map(product=>(
                <div className="row bg-grey product-bill" key={product.timestamp}>
                    
                        <div className="col-8">
                            <h4>{product.is_size_selected ? `${product.size_name} ` : null}{product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null}{product.name}</h4>
                        </div>
                        <div className="col-4 text-right pl0">
                            <h4 className="pricing-tag">
                                ${(parseFloat(product.price) + parseFloat(product.ingredientTotal)).toFixed(2) }
                                <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeItem(product)}  />
                            </h4>
                            
                            {/* <h4 className="pricing-tag">$ {(parseFloat(product.price)).toFixed(2) }</h4> */}
                        </div>
                        <div className="col-12 pl20">
                            <ul>
                                {
                                    product.isShowIngredientsList ? RenderIngredients(product.ingredients) : null
                                    
                                }
                            </ul>
                            {/* <p>
                                {
                                    product.ingredients.length ? product.ingredients.map((ingredient,index) =>
                                            (<span key={index}> {ingredient.name} , {index} </span>)) : ''
                                }
                            </p> */}
                            {/* <p>tomato, Mayyanoise, cheese Slice, large Egg</p> */}
                        </div>
                        {/* <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                            <div className="cursor-pointer" onClick={()=> removeItem(product)} ><img src={trashRedPng} alt="trash" className="mw-100" /></div>
                        </div> */}
                        <UserCartItemSubBlock
                                    mainProduct={product}
                                    products={product.selectedComboItems}
                                    type="cart"
                                    removeSubItemFromCart={removeSubItemFromCart}
                                />
                        
                        {/* <div className="item btn">
                            <div className="cart_remove_btn" 
                                onClick={()=> removeItem(product._id)}>
                                Remove
                            </div>
                        </div> */}
                </div>
            ))

        :null
    )


    return (
        <div>
            {products ?
                        products.length === 0 ?
                            <div className="text-center mt20 mb20">
                                <h3>The cart is empty</h3>
                            </div>
                        :null
                    :null}
            {renderItems()}
        </div>
    );
};

export default UserCartItemBlock;
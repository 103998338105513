import React, { Component } from 'react';
// import MyButton from './button';

import { connect } from 'react-redux';
// import { addToCart } from '../../actions/user_actions';
// import chickenKabobPng from '../../../common/images/products/chicken-kabob.png'
import {S3_URL} from '../../../utils/misc'
// import chickenKabobPng from '../../common/images/products/chicken-kabob.png'
import { withRouter } from 'react-router-dom';
import CheckBox from './CheckBox';

class MenuItem extends Component {

    renderCardImage(images){
        if(images.length > 0){
            return images[0].url
        } else {
            return '/images/image_not_availble.png'
        }
    }


    render() {
        const props = this.props;
        console.log('props')
        console.log(props)
        return (
            <div className={`card_item_wrapper `} onClick={()=> props.handleCheckChieldElement(props._id)}>
                <div className="product-item product-combo-item d-block pl-2 pr-3 py-2 mb-3" >
                {/* <a href="#" className="product-item product-combo-item d-block pl-2 pr-3 py-2 mb-3" > */}
                    <div className="row">
                        <div className="col-4 col-lg-3 pr-0">
                            <div className="prod-img">
                                <img src={`${S3_URL}${props.s3_image_path}`} alt="products" className="mw-100" />
                            </div>
                        </div>
                        <div className="col-8 col-lg-9">
                            <div className="prod-info">
                                <h4 className="combo-pro-title">{props.name}</h4>
                                {/* <p>
                                    {props.description}
                                </p> */}
                                {/* <div className="text-right font-italic float-right mt10"><label className="checkbox"><input type="checkbox" defaultChecked disabled="disabled" /><span className="checkmark"></span></label></div> */}
                                {/* <div className="text-right font-italic float-right mt10"><CheckBox handleCheckChieldElement={props.handleCheckChieldElement}  id={props._id} checked={false} value={props._id} /></div> */}
                                <div className="text-right font-italic float-right mt10"><CheckBox  handleCheckChieldElement={props.handleCheckChieldElement} id={props._id} checked={props.isChecked}  value={props._id} /></div>
                                <h4 className="text-right font-italic clear-both combo-pro-price">$ {props.combo_item_price}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </a> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(withRouter(MenuItem));
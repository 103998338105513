import {
    FETCH_MENU_ITEM_BY_ID,
} from '../actions/types';


export default function(state={},action){
    switch(action.type){
        case FETCH_MENU_ITEM_BY_ID:
           
            return {
                    ...state,
                    menuItem: action.payload.menuItem,
                    // total_menu_items: action.payload.total_menu_items
                }
        break;
    default:{
        // console.log('state')
        // console.log(state)
        // console.log(...state)
        return  {
            ...state
        };
    }
    }
}
import React,{Fragment} from 'react';
import OrderItem from './OrderItem';
import { withRouter } from 'react-router-dom';

const OrderItemBlock = (props) => {

    const renderOrders = () => (
        props.list ? 
            props.list.map(card=>(
                <OrderItem
                    key={card._id}
                    {...card}
                />
            ))
        :null
    )

    return (
        <Fragment>
            {props.list ?
                props.list.length === 0 ?
                    <div className="no_result text-center">
                        You have not placed any orders yet.
                    </div>
                :null
            :null}
            { renderOrders(props.list)}

        </Fragment>
    );
};

export default withRouter(OrderItemBlock);
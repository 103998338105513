import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { showMsg } from '../../utils/general';
import { USER_SERVER } from '../../utils/misc';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const initialValuesSchema = {
    // email: '',
    password: '',
    confirmPassword: '',
}
const RegisterSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password Too Short!')
        .max(50, 'Password Too Long!')
        .required('Password is Required'),
    confirmPassword: Yup.string()
        .min(8, 'Password Too Short!')
        .max(50, 'Password Too Long!')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    // email: Yup.string()
    //     .email('Please provide a valid email')
    //     .required('Email is Required'),
    
        
});

class ResetUserPassword extends Component {
    _isMounted = false;
    state = {
        resetToken: '',
        isloading: false,
        formError: false,
        formSuccess:'',
    }
    
    componentDidMount() {
        this._isMounted = true;
        const resetToken = this.props.match.params.token;
        this.setState({ resetToken })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    onSubmitForm = async (fields) => {
        console.log('fields in submit')
        console.log(fields)
        if (this._isMounted) {
            this.setState({
                formError: false,
                isloading:true,
            })
        }

        this.setState({isloading:true})
        axios.post(`${USER_SERVER}/reset_password`,
            {
                ...fields,
                resetToken: this.state.resetToken
            }
        ).then(response=>{
            if(response.data.success){
                showMsg("Your password was reseted login to continue",'success');
                // this.setState({
                //     formSuccess:true
                // })
                let is_checkout_redirect = this.props.match.params.is_checkout_redirect;
                let delivery_type = this.props.match.params.delivery_type;
                console.log('is_checkout_redirect')
                console.log('is_checkout_redirect')
                console.log(is_checkout_redirect)
                console.log('delivery_type')
                console.log('delivery_type')
                console.log(delivery_type)
                if((is_checkout_redirect === undefined || is_checkout_redirect === 'undefined') || (delivery_type === undefined || delivery_type === 'undefined')){
                    this.props.history.push('/login');
                }else{
                    this.props.history.push(`/address-details/${delivery_type}`);
                }
                // this.props.history.push('/login');
            }else{
                if(response.data.msg){
                    showMsg(response.data.msg,'error');
                }
            }
            this.setState({
                isloading:false,
            })
        })
        
        
    }
    render() {
        // console.log('this.state.isloading')
        // console.log(this.state.isloading)
        return (
                <Fragment>
                    <header className="container inner-header">
                        <div className="row">
                            <div className="col-12">
                                <h4>Reset your password</h4>
                            </div>
                        </div>
                    </header>
                    <div className="inner-body addressdetails-main">
                        <div className="container my-2">
                            <Formik
                                initialValues={initialValuesSchema}
                                validationSchema={RegisterSchema}
                                onSubmit={this.onSubmitForm}
                                render={({ errors, status, touched }) => (
                                    <Form>
                                        
                                        {/* <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Enter your email" name="email" type="text" className={'form-control form-control2' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div> */}
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Enter your password" name="password" type="password" className={'form-control form-control2' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                <ErrorMessage name="password" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Confirm your password" name="confirmPassword" type="password" className={'form-control form-control2' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        { this.state.formError ?
                                            <div className="error_label text-danger text-center mt20 mb20" role="alert">
                                                Please provide valid data
                                            </div>
                                        :null}
                                        {
                                            this.state.isloading ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
                                        }
                                        <div className="row product-action-btn mb-2 px-2">
                                            <div className="col-12">
                                                <div className="my-cart-btn">
                                                    <button type="submit" className="btn btn-primary w-100">Reset Password</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </div>
                </Fragment>
                
        
        )
    }
}


// export default Register
export default connect()(withRouter(ResetUserPassword));

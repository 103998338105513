import React from 'react';
import MenuItemBlock from './MenuItemBlock'; 

const LoadMoreMenuItems = (props) => {
    return (
        <div>
            <div>
                <MenuItemBlock
                    list={props.MenuItems}
                    menuCategoryId={props.menuCategoryId}
                />
            </div>
            {
                // props.total_menu_items > 0 && props.total_menu_items >= props.limit ?
                props.total_menu_items > 0 && props.total_menu_items > props.MenuItems.length ?
                    <div className="load_more_container text-center"> {/* Menu Item total{props.total_menu_items} */}
                        <div className="cursor-pointer" onClick={()=> props.loadMore()}><i className="fas fa-angle-down show-more"></i></div>
                        {/* <span onClick={()=> props.loadMore()}>
                            Load More
                        </span> */}
                    </div>
                :null
            }
            

    
        </div>
    );
};
export default LoadMoreMenuItems;

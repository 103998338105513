import React, { Component } from 'react'
import { connect } from 'react-redux';
import dropLeftPng from '../../common/images/icons/drop-left.png';
import LoadMoreComboItems from './components/LoadMoreComboItems';
import { fetchMenuItemById,fetchMenuItemCombosByMenuItemId  } from '../../../actions/menu_actions';
import { Link, withRouter } from 'react-router-dom';
import {getParameterByName } from '../../utils/general';
/* import chickenKabobPng from '../../common/images/products/chicken-kabob.png'
import beefLambGyroPng from '../../common/images/products/beef-lamb-gyro.png'
import gyroParmesanPng from '../../common/images/products/gyro-parmesan.png'
import heidisSpecialPng from '../../common/images/products/heidis-special.png' */
import './css/AddComboItemsToMenuItem.css';
import {S3_URL} from '../../utils/misc';
import LoadingSpinner from '../../utils/LoadingSpinner';
import CartButton from '../../common/components/CartButton';
class AddComboItemsToMenuItem extends Component {
    _isMounted = false;
    state = {
        menu_item_id:'',
        menuCategoryId:'',
        selectedComboItems:[],
        MenuItemCombos:[],
        size_name:null,
        size_price:null,
        special_modifier_name:null,
        special_modifier_price:null,
    }

    

    componentDidMount() {
        this._isMounted = true;
        const menu_item_id = this.props.match.params.mitem_id;
        let menuCategoryId = getParameterByName('menuCategoryId');
        let size_name = getParameterByName('size_name');
        let size_price = getParameterByName('size_price');
        let special_modifier_name = getParameterByName('special_modifier_name');
        let special_modifier_price = getParameterByName('special_modifier_price');
        // console.log('size_name')
        // console.log(size_name)
        // console.log('size_price')
        // console.log(size_price)
        this.setState({ menu_item_id,menuCategoryId,size_name,size_price,special_modifier_name,special_modifier_price })
        this.props.fetchMenuItemById(menu_item_id);
        this.props.fetchMenuItemCombosByMenuItemId(menu_item_id).then(response =>{
            if (this._isMounted) {
                let MenuItemCombos = this.props.MenuItemCombos.slice();
                console.log('MenuItemCombos in then')
                console.log(MenuItemCombos)
                for (let index = 0; index < MenuItemCombos.length; index++) {
                    MenuItemCombos[index].isChecked = false;
                }
                console.log('MenuItemCombos in after')
                console.log(MenuItemCombos)
                this.setState({MenuItemCombos:MenuItemCombos},()=>{
                    console.log('this.state.MenuItemCombos')
                    console.log(this.state.MenuItemCombos)
                })
            }
        });
        // console.log('this.props.menuCategories')
        // console.log(this.props.menuCategories)
    }
    constructor(props) {
        super(props);
        // this.handleCheckChieldElement = this.handleCheckChieldElement.bind(this);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleCheckChieldElement = (selectedValue) => {
        console.log('selectedValue')
        console.log(selectedValue)
        // console.log(event.target)
        // console.log('event.target.value')
        // console.log(event.target.value)
        // let selectedValue = event.target.value;
        // let selectedValue = event;
        let selectedComboItems = this.state.selectedComboItems;
        console.log('selectedComboItems')
        console.log(selectedComboItems)
        let selectedComboItemIndex = selectedComboItems.findIndex(x => x === selectedValue);
        if (selectedComboItemIndex !== -1 && selectedComboItemIndex !== '-1') {
            selectedComboItems.splice(selectedComboItemIndex, 1);
        }else{
            /* let obj = {};
            obj.id = selectedValue; */
            selectedComboItems.push(selectedValue)
            // selectedComboItems.push(obj)
        }
        let MenuItemComboIndex = this.state.MenuItemCombos.findIndex(x => x._id === selectedValue);
        console.log('MenuItemComboIndex')
        console.log(MenuItemComboIndex)
        if (MenuItemComboIndex !== -1 && MenuItemComboIndex !== '-1') {
            let MenuItemCombo = this.state.MenuItemCombos.find(x => x._id === selectedValue);
            let MenuItemCombos = [...this.state.MenuItemCombos] // important to create a copy, otherwise you'll modify state outside of setState call
            MenuItemCombo.isChecked = !MenuItemCombo.isChecked;
            MenuItemCombos[MenuItemComboIndex] = MenuItemCombo;
            this.setState({MenuItemCombos});
        }
        //     const index = this.state.employees.findIndex(emp => emp.id === employee.id),
        //       employees = [...this.state.employees] // important to create a copy, otherwise you'll modify state outside of setState call
        // employees[index] = employee;
        // this.setState({employees});
        // if (MenuItemComboIndex !== -1 && MenuItemComboIndex !== '-1') {
        //     selectedComboItems.splice(selectedComboItemIndex, 1);
        //     // this.state.MenuItemCombos[MenuItemComboIndex]isChecked
        // }else{
           
        //     selectedComboItems.push(selectedValue)
        // }
        // this.state.MenuItemCombos.forEach(fruite => {
        //    if (fruite.value === event.target.value){
        //        fruite.isChecked =  event.target.checked
        //    }
        // })
        // console.log('selectedComboItems')
        // console.log(selectedComboItems)
        this.setState({selectedComboItems: selectedComboItems})
        // console.log('this.state.selectedComboItems.join(",")')
        // console.log(this.state.selectedComboItems.join(","))
    }

    /* handleCheckChieldElement = (event) => {
        console.log('event')
        console.log(event)
        // console.log(event.target)
        console.log('event.target.value')
        console.log(event.target.value)
        let selectedValue = event.target.value;
        // let selectedValue = event;
        let selectedComboItems = this.state.selectedComboItems;
        let CommentIndex = selectedComboItems.findIndex(x => x.id === selectedValue);
        if (CommentIndex !== -1 && CommentIndex !== '-1') {
            selectedComboItems.splice(CommentIndex, 1);
        }else{
            
            selectedComboItems.push(selectedValue)
            // selectedComboItems.push(obj)
        }
        
        // console.log('selectedComboItems')
        // console.log(selectedComboItems)
        this.setState({selectedComboItems: selectedComboItems})
        // console.log('this.state.selectedComboItems.join(",")')
        // console.log(this.state.selectedComboItems.join(","))
    } */
    

    
    
    render() {
        const menuItem = this.props.menuItem;
        // let MenuItemCombos = this.props.MenuItemCombos;
        let MenuItemCombos = this.state.MenuItemCombos;
        console.log('MenuItemCombos in redner')
        console.log(MenuItemCombos)
        
        if (Object.keys(menuItem).length === 0 || MenuItemCombos === undefined  || (MenuItemCombos && MenuItemCombos.length === 0) ) {
        // if (MenuItemCombos.length == 0 || Object.keys(menuItem).length == 0) {
                // if (Object.keys(menuItem).length == 0) {
            return (
                <LoadingSpinner subClass={'mt10 mb10'} />
            )
        }else{
            const objMenuItem = menuItem.menuItem;
            let sizeBool = ((this.state.size_name !== 'null' && this.state.size_name !== null) &&(this.state.size_price !== 'null' && this.state.size_price !== null));
            let specialModifierBool = ((this.state.special_modifier_name !== 'null' && this.state.special_modifier_name !== null) &&(this.state.special_modifier_price !== 'null' && this.state.special_modifier_price !== null));
            let pathName = `/menu-item/add-ingredients/${objMenuItem._id}?selectedCstateomboItems=${this.state.selectedComboItems.join(",")}&menuCategoryId=${this.state.menuCategoryId}`;
            if(specialModifierBool && sizeBool){
                pathName = `/menu-item/add-ingredients/${objMenuItem._id}?selectedCstateomboItems=${this.state.selectedComboItems.join(",")}&menuCategoryId=${this.state.menuCategoryId}&size_name=${this.state.size_name}&size_price=${this.state.size_price}&special_modifier_name=${this.state.special_modifier_name}&special_modifier_price=${this.state.special_modifier_price}`
            }else if(sizeBool){
                pathName = `/menu-item/add-ingredients/${objMenuItem._id}?selectedCstateomboItems=${this.state.selectedComboItems.join(",")}&menuCategoryId=${this.state.menuCategoryId}&size_name=${this.state.size_name}&size_price=${this.state.size_price}`
            }else if(specialModifierBool){
                pathName = `/menu-item/add-ingredients/${objMenuItem._id}?selectedCstateomboItems=${this.state.selectedComboItems.join(",")}&menuCategoryId=${this.state.menuCategoryId}&special_modifier_name=${this.state.special_modifier_name}&special_modifier_price=${this.state.special_modifier_price}`
            }
            let menuItemPrice = 0;
            if(specialModifierBool && sizeBool){
                if(this.state.special_modifier_price !== '' && isNaN(this.state.special_modifier_price) === false){
                    menuItemPrice =  (parseFloat(this.state.size_price) +  parseFloat(this.state.special_modifier_price)).toFixed(2);
                }else{
                    menuItemPrice = parseFloat(this.state.size_price).toFixed(2);
                }
            }else if(sizeBool){
                menuItemPrice = parseFloat(this.state.size_price);
            }else if(specialModifierBool){
                if(this.state.special_modifier_price !== '' && isNaN(this.state.special_modifier_price) === false){
                    menuItemPrice =  (parseFloat(objMenuItem.price) +  parseFloat(this.state.special_modifier_price)).toFixed(2);
                }else{
                    menuItemPrice = objMenuItem.price;    
                }
            }else{
                menuItemPrice = objMenuItem.price;
            } 
            // for (let index = 0; index < MenuItemCombos.length; index++) {
            //     const element = MenuItemCombos[index];
            //     MenuItemCombos[index].isChecked = false;
            // }
            // console.log('objMenuItem.combo_item_price')
            // console.log(objMenuItem.combo_item_price)
            // objMenuItem.combo_item_price = 222;
            return (
                <section className="container home-main">
                    <div className="row">
                        <div className="col-12 mb20">
                            <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={this.props.history.goBack} />
                        </div>
                    </div>
                    <div className="row">
    
                        {/* <!-- product select --> */}
                        <div className="col-12 mb-3">
                            <div className="position-relative">
                                <div className="food-ul-link position-relative AvenirNextMedium d-flex align-items-center cursor-pointer" > 
                                    { this.state.size_name !== 'null' && this.state.size_name !== null ? `${this.state.size_name} ` : null}{ this.state.special_modifier_name !== 'null' && this.state.special_modifier_name !== null ? `${this.state.special_modifier_name} ` : null}{objMenuItem.name}
                                </div>
                            </div>
                        </div>
                        {/* <!-- product select --> */}
    
                        
                        <div className="col-12">
                            <div className="product-item d-block pl-2 pr-3 py-2 mb-3">
                            {/* <a href="#" className="product-item d-block pl-2 pr-3 py-2 mb-3"> */}
                                <div className="row">
                                    <div className="col-4 col-lg-3 pr-0">
                                        <div className="prod-img">
                                            <img src={`${S3_URL}${objMenuItem.s3_image_path}`} alt="products" className="mw-100" />
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-9">
                                        <div className="prod-info">
                                            <h4 className="combo-pro-title">{ this.state.size_name !== 'null' && this.state.size_name !== null ? `${this.state.size_name} ` : null}{objMenuItem.name}</h4>
                                            {/* <p>
                                                {objMenuItem.description}
                                            </p> */}
                                            <div className="text-right font-italic float-right mt10"><label className="checkbox"><input type="checkbox" defaultChecked disabled="disabled" /><span className="checkmark"></span></label></div>
                                            {/* <h4 className="text-right font-italic clear-both combo-pro-price">$ {objMenuItem.price}</h4> */}
                                            {/* <h4 className="text-right font-italic clear-both combo-pro-price">$ { this.state.size_price !== 'null' && this.state.size_price !== null ? `${parseFloat(this.state.size_price).toFixed(2)}` : `${objMenuItem.price}`}</h4> */}
                                            <h4 className="text-right font-italic clear-both combo-pro-price">${menuItemPrice} </h4>
                                        </div>
                                    </div>
                                </div>
                            {/* </a> */}
                            </div>

                            <h6 className="mb20 color-white text-center">Make it a combo (optional)</h6>

                            <LoadMoreComboItems
                                    ComboMenuItems={MenuItemCombos}
                                    ComboItemPrice={objMenuItem.combo_item_price}
                                    // ComboItemPrice={objMenuItem.combo_item_price}
                                    handleCheckChieldElement={this.handleCheckChieldElement}
                                    // handleCheckChieldElement={(event)=> this.handleCheckChieldElement(event)}
                                />
                            
                            
                            
                            
                            
                            
                        </div>
                        <div className="col-12 mb-10">
                            <div className="my-cart-btn">
                                <Link className="btn btn-primary w-100 position-relative"
                                    to={{
                                        // pathname: `/menu-item/add-ingredients/${objMenuItem._id}?selectedCstateomboItems=${this.state.selectedComboItems.join(",")}&menuCategoryId=${this.state.menuCategoryId}`,
                                        pathname:pathName
                                        // selectedComboItems: this.state.selectedComboItems // your data array of objects
                                    }}
                                    
                                > Continue </Link>
                                {/* <button className="btn btn-primary w-100 position-relative">
                                    Continue
                                    
                                </button> */}
                            </div>
                        </div>
    
                        {/* <!-- my cart button --> */}
                        <div className="col-12">
                            <div className="my-cart-btn">
                                <CartButton 
                                    cart={this.props.cart}
                                />
                                {/* <button className="btn btn-light w-100 position-relative">
                                    MY CART
                                    <span className="position-absolute">1 Item</span>
                                </button> */}
                            </div>
                        </div>
                        {/* <!-- my cart button --> */}
    
                    </div>
                        
                </section>
            
            )
        }
        
    }
}

function mapStateToProps(state) {
    // console.log('state')
    // console.log(state)
    return { 
        menuItem : state.menuItem,
        MenuItemCombos : state.MenuItems.MenuItemCombos
    };
}

// export default Home
export default connect(mapStateToProps, { fetchMenuItemById,fetchMenuItemCombosByMenuItemId })(withRouter(AddComboItemsToMenuItem));

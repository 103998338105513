import React,{Fragment} from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import PhoneReceiverImg from '../Pages/Home/images/phone-receiver.png'
import menuPng from '../common/images/icons/menu.png'
// import oldLogoPng from '../common/images/old-logo.png'
import userPng from '../common/images/icons/user.png'
import logoutPng from '../common/images/icons/logout.png'
// SideMenu
// import oldSidemenuLogo from '../common/images/old-sidemenu-logo.png'
import menuListPng from '../common/images/icons/menu-list.png'
import myCartPng from '../common/images/icons/my-cart.png'
import bookOrderPng from '../common/images/icons/book-order.png'
// import fevPng from '../common/images/icons/fev.png'
import aboutUsPng from '../common/images/icons/about-us.png'
import ourPolicyPng from '../common/images/icons/our-policy.png'
import { logoutUser } from '../../actions/user_actions';
import { fetchResturantLogo  } from '../../actions/settings_actions';
// import fbPng from '../common/images/icons/fb.png'
// import whatsAppPng from '../common/images/icons/whatsapp.png'
// import othersPng from '../common/images/icons/others.png'
import $ from 'jquery';
import { Link } from 'react-router-dom';
import io from "socket.io-client";
import {showMsg} from '../utils/general';
import {BASE_URL, S3_URL} from '../utils/misc';
var socket;
// const Header = (props) => {
class Header extends React.Component {
    /* constructor(props) {
        super(props);
        this.compareShow = React.createRef();
        this.slideToggle = this.slideToggle.bind(this);
    }
    
    slideToggle() {
        console.log('ss');
        $(this.refs.compareShow.current).slideToggle();
    } */
    state={
        isChanged:'',
        headerLogo:false,
    }
    constructor(props) {
        super(props)
        this._toggleDiv = this._toggleDiv.bind(this);
        this.state = {
            endpoint: BASE_URL // Update 5000 with port on which backend-my-app/server.js is running.
            // endpoint: "http://jubzi.com/" // Update 5000 with port on which backend-my-app/server.js is running.
            // endpoint: "http://localhost:5000/" // Update 5000 with port on which backend-my-app/server.js is running.
            // endpoint: "http://localhost:3001/" // Update 3001 with port on which backend-my-app/server.js is running.
        };
        const  connectionOptions =  {
                    "force new connection" : true,
                    "reconnection": true,
                    "reconnectionDelay": 2000,                  //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
                    "reconnectionDelayMax" : 60000,             //1 minute maximum delay between connections
                    "reconnectionAttempts": "Infinity",         //to prevent dead clients, having the user to having to manually reconnect after a server restart.
                    "timeout" : 10000,                           //before connect_error and connect_timeout are emitted.
                    "transports" : ["websocket"]                //forces the transport to be only websocket. Server needs to be setup as well/
                }
        socket = io(this.state.endpoint,connectionOptions);
        // socket = io(this.state.endpoint,{transports: ['websocket']});
        // socket = io(this.state.endpoint);
        // console.log('socket in constructor')
        // console.log(socket)
    }
      
    _toggleDiv() {
        // console.log('d')
        $(this.refs['sidemenu']).toggle( "slide" )
    }
    logoutHandler = () => {
        // alert('logoutHandler')
        this.props.logoutUser().then(response => {
            // console.log('this.props.user after login')
            // console.log(this.props.user)
            if(this.props.user.logOutSuccess && this.props.user.logOutSuccess.success){
                localStorage.removeItem('token')
                localStorage.removeItem('homePageCustomerFormValues')
                localStorage.removeItem('orderSubmitFormValues')
                // localStorage.removeItem('push_notification_subscription_token')
                showMsg("You have been successfully loged out.",'success');
                this._toggleDiv();
                this.setState({
                    isChanged:'',
                })
                this.props.history.push('/')
            }else{
                showMsg("Opps Something went wrong please try again.",'error');
            }
        });
        /* this.props.dispatch(logoutUser()).then(response =>{
            console.log('response inn logout')
            console.log(response)
            if(response.payload.success){
                this.props.history.push('/')
            }
        }) */
    }
    componentDidMount() {
        // socket.emit("putOrder", 'dsdsdshdsjgdshjgsdh');
        this.props.fetchResturantLogo().then(response => {
            this.setState({headerLogo:this.props.objRestaurantLogo.restaurant_logo},()=>{
            });
        });
    }
    /*componentDidMount() {
        console.log('i am componentDidMount socket')
        console.log('i am componentDidMount socket')
        console.log('i am componentDidMount socket')
        console.log('i am componentDidMount socket')
        socket.on('admin_order_placed', function(order) {
            console.log('order in admin_order_placed')
            console.log(order)
            
        });
        socket.on("orderschannel12",  (message) => {
            console.log('message in orderschannel12');
            // console.log(message.user_id);
            console.log(message);
        });
        socket.on("orders-channel:orderPlacedFromWeb",  (message) => {
            console.log('message in orderPlacedFromWeb');
            // console.log(message.user_id);
            console.log(message);
        });
        console.log(socket)
    }
    componentWillUnmount() {
        console.log()
        socket.off("orderschannel12");
        socket.off("orders-channel:orderPlacedFromWeb");
    }*/
    render (){
        // console.log('this.props.user in the header');
        // console.log(this.props.user)
        let user = this.props.user.userData;
        // console.log('user.isAuth in header')
        if(user){
            // console.log(user.isAuth)
        }
        return (
            <Fragment>
                <header className="container header-main pb-4">
                    <div className="row position-relative">
                        <div className="col-3 d-flex align-items-center">
                            <div className="header-menu">
                                <div  className="sidemenuClick cursor-pointer" onClick={this._toggleDiv}>
                                    <img src={menuPng} alt="menu" className="mw-100" />
                                </div>
                                {/* <a href="#" className="sidemenuClick" onClick={this._toggleDiv}>
                                    <img src={menuPng} alt="menu" className="mw-100" />
                                </a> */}
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center">
                            <div className="header-logo">
                                <Link to='/'>
                                    {
                                        this.state.headerLogo ?
                                        <img src={`${S3_URL}${this.state.headerLogo}`} alt="Logo" className="mw-100" />
                                        : null
                                    }
                                    {/* <img src={oldLogoPng} alt="Logo" className="mw-100" /> */}
                                </Link>
                            </div>
                        </div>
                        <div className="col-3 d-flex align-items-center justify-content-end">
                            <div className="header-user">
                                <Link to='/login'>
                                    <img src={userPng} alt="login" title="Click here to login" className="mw-100" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="row sidemenu position-absolute"  ref="sidemenu"  >
                    <div className="sidemenu-close sidemenuClick position-absolute cursor-pointer" onClick={this._toggleDiv}><i className="far fa-times-circle"></i></div>
                    {/* <a href="#" className="sidemenu-close sidemenuClick position-absolute" onClick={this._toggleDiv}><i className="far fa-times-circle"></i></a> */}
                    {/* <!-- side menu header --> */}
                    <div className="col-12 sidemenu-header d-flex align-items-center justify-content-center">
                        <div className="sidemenu-logo">
                            <Link to='/'>
                                {
                                    this.state.headerLogo ?
                                    <img src={`${S3_URL}${this.state.headerLogo}`} alt="logo" className="mw-100" onClick={this._toggleDiv} />
                                    : null
                                }
                                {/* <img src={oldSidemenuLogo} alt="logo" className="mw-100" onClick={this._toggleDiv} /> */}
                            </Link>
                        </div>
                    </div>
                    {/* <!-- side menu header --> */}

                    {/* <!-- side menu list --> */}
                    <div className="col-12 sidemenu-list d-flex align-items-center justify-content-center text-center">
                        <div className="row">
                            <div className="col-6">
                                {/* <a href="#"> */}
                                <Link to='/' onClick={this._toggleDiv}>
                                    <div className="list-img">
                                        <img src={menuListPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>MENU LIST</p>
                                {/* </a> */}
                                </Link>
                            </div>
                            <div className="col-6">
                                {/* <a href="#"> */}
                                <Link to='/my-cart' onClick={this._toggleDiv}>
                                    <div className="list-img">
                                        <img src={myCartPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>MY CART</p>
                                {/* </a> */}
                                </Link>
                            </div>
                            <div className="col-6">
                                <Link to="/order-history" onClick={this._toggleDiv}>
                                    <div className="list-img">
                                        <img src={bookOrderPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>Order History</p>
                                </Link>
                            </div>
                            <div className="col-6">
                                {
                                    (user && user.isAuth) ?
                                        <div className="cursor-pointer logoutDiv" onClick={()=> this.logoutHandler()}>
                                            <div className="list-img">
                                                <img src={logoutPng} alt="icons" className="mw-100" />
                                            </div>
                                            <p>Logout</p>
                                        </div>
                                    :
                                        <Link to="/register" onClick={this._toggleDiv}>
                                            <div className="list-img">
                                                <img src={userPng} alt="icons" className="mw-100" />
                                            </div>
                                            <p>Signup</p>
                                        </Link>
                                }
                            </div>
                            <div className="col-6" >
                                {/* <Link to="/"> */}
                                    <div className="list-img">
                                        <img src={aboutUsPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>ABOUT US</p>
                                {/* </Link> */}
                            </div>
                            <div className="col-6">
                                {/* <Link to="/"> */}
                                    <div className="list-img">
                                        <img src={ourPolicyPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>OUR POLICY</p>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- side menu list --> */}

                    {/* <!-- side menu footer --> */}
                    {/* <div className="col-12 sidemenu-footer text-center">
                        <div className="row">
                            <div className="col-12 text-left">
                                <h4><i>SHARE</i></h4>
                            </div>
                            <div className="col-4">
                                <Link to='/'>
                                    <div className="list-img">
                                        <img src={fbPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>FACEBOOK</p>
                                </Link>
                            </div>
                            <div className="col-4">
                                <Link to='/'>
                                    <div className="list-img">
                                        <img src={whatsAppPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>WHATSAPP</p>
                                </Link>
                            </div>
                            <div className="col-4">
                                <Link to='/'>
                                    <div className="list-img">
                                        <img src={othersPng} alt="icons" className="mw-100" />
                                    </div>
                                    <p>OTHERS</p>
                                </Link>
                            </div>
                        </div>
                    </div> */}
                    {/* <!-- side menu footer --> */}

                </div>
            </Fragment>
        )
    }
}



// export default Header
// function mapStateToProps({ auth }) {
//     return { auth };
// }
function mapStateToProps(state){
    return {
        user: state.user,
        objRestaurantLogo : state.settings.objRestaurantLogo,
        // is_resturant_open:state.is_resturant_open
    }
}
// console.log('socket in header');
// console.log(socket)
export  { socket };
// export default Header;
export default connect(mapStateToProps,{logoutUser,fetchResturantLogo})(withRouter(Header));
// export default connect(mapStateToProps, { fetchResturantTax,removeCartItem,removeCartSubItem })(withRouter(Cart));



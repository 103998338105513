import React from 'react'
import {S3_URL} from '../../../../utils/misc';


const RenderCarouselImages = (props) => {

    
    return (
        <div className="prod-img">
            <img src={`${S3_URL}${props.s3_image_path}`} alt={`${props.name}`} className="mw-100" />
        </div>
    )  
                
}
export default RenderCarouselImages;



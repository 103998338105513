import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { auth,isResturantOpen } from '../actions/user_actions';
import { checkIfRestaurantIsOpen } from '../actions/settings_actions';
import { NoInternetModal } from '../components/common/modals/NoInternetModal/NoInternetModal';
// import { isResturantOpen } from '../actions/user_actions';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Spinner } from 'reactstrap';
import { showMsg } from '../components/utils/general';


export default function(ComposedClass,reload,checkAUth = null,adminRoute = null){
    class AuthenticationCheck extends Component {
        _isMounted = false;
        state = {
            loading: true,
            showNoInternetModalModal : false,
        }

        componentDidMount(){
            this._isMounted = true;
            this.props.checkIfRestaurantIsOpen().then(response =>{
                // console.log('this.props.isRestaurantIsOpen');
                // console.log('this.props.isRestaurantIsOpen');
                // console.log(this.props.isRestaurantIsOpen);
                let isRestaurantIsOpen = this.props.isRestaurantIsOpen.is_restaurant_open;
                
                // this.props.isResturantOpen().then(response =>{
                    
                    this.props.auth().then(response =>{
                        /* console.log('before making state false')
                        this.setState({loading:false})
                        console.log('after making state false') */
                        // console.log('response')
                        // console.log(response)
                        // this.props.dispatch(auth()).then(response =>{
                        // let user = this.props.user.userData;is_resturant_open
                        // let is_resturant_open = this.props.user.is_resturant_open.is_resturant_open;
                        // let cart = this.props.cart;
                        // console.log('this.props.user.userData')
                        // console.log(this.props.user.userData)
                        let user = this.props.user.userData;
                        // console.log('is_resturant_open')
                        // console.log(is_resturant_open)
                        // isRestaurantIsOpen = true;
                        if(!isRestaurantIsOpen){
                            if(reload){
                                showMsg("Restaurant is currently closed. Please check back during business hours.",'info');
                                // this.props.history.push('/resturant-closed')
                            }
                        }
                        /* if(!is_resturant_open){
                            if(reload){
                                // this.setState({loading:false})
                                this.props.history.push('/resturant-closed')
                            }
                        } */
                        // console.log('user.isAuth')
                        // console.log(user.isAuth)
                        // console.log('checkAUth')
                        // console.log(checkAUth)
                        if(checkAUth && !user.isAuth){
                            // console.log('i am in checkAUth')
                            if(reload){
                                // this.setState({loading:false})
                                // console.log('i am in reload of login')
                                this.props.history.push('/login')
                            }
                        }else if(checkAUth && user.isAuth){
                            // if user is already logged in and vists the login and register page then we redirect him to home page
                            if(reload === false){
                                // this.setState({loading:false})
                                this.props.history.push('/');
                            }
                        }
                        /* if(!user.isAuth){
                            if(reload){
                                this.props.history.push('/register_login')
                            }
                        } else{
                            if(adminRoute && !user.isAdmin){
                                this.props.history.push('/user/dashboard')
                            } else{
                                if(reload === false){
                                    this.props.history.push('/user/dashboard')
                                }
                            }
                        } */
                        if (this._isMounted) {
                            this.setState({loading:false})
                        }
                    });
                // });
            });
            window.addEventListener('offline', () => {
                console.log("You lost connection.");
                this.setState({showNoInternetModalModal:true});
            });
              
            window.addEventListener('online', () => {
                console.log("You are now back online.");
                this.setState({showNoInternetModalModal:false});
            });
        }
        componentWillUnmount() {
            this._isMounted = false;
            window.removeEventListener('offline',() => {
            });
            window.removeEventListener('online',() => {
            });
        }
        handleCloseShowNoInternetModalModal = () => {
            this.setState({ showNoInternetModalModal: false })
        }


        render() {
            // console.log('this.state.loading in render')
            // console.log(this.state.loading)
            if(this.state.loading){
                return (
                    <div className="main_loader">
                        <Spinner color="primary" />
                        {/* <CircularProgress style={{color:'#2196F3'}} thickness={7}/>  */}
                    </div>
                )
            }
            // console.log('after state loading in render')
            // console.log('this.state.loading in render')
            // console.log(this.state.loading)
            return (
                <Fragment>
                    <NoInternetModal
                        showNoInternetModalModal={this.state.showNoInternetModalModal} 
                        handleCloseShowNoInternetModalModal={this.handleCloseShowNoInternetModalModal}
                    />
                   <ComposedClass {...this.props} user={this.props.user} cart={this.props.cart} isRestaurantOpen={this.props.isRestaurantIsOpen.is_restaurant_open} />
                </Fragment>
            );
        }
    }

    function mapStateToProps(state){
        return {
            user: state.user,
            cart: state.cart,
            isRestaurantIsOpen : state.settings.isRestaurantIsOpen
            // is_resturant_open:state.is_resturant_open
        }
    }

    return connect(mapStateToProps,{checkIfRestaurantIsOpen,auth,isResturantOpen})(AuthenticationCheck)
}



export function validateDeliveryAmount(delivery_amount,objRestaurantPhoneNumber) {
    let error = false;
    let msg = '';
    if(delivery_amount > 50){
        // alert('Error: Please enter a valid address');
        msg = 'Please enter a valid adfress.  Include address and city';
        error = true;
    }else if(delivery_amount === 0 || delivery_amount === 0.00){
        
        let restaurantPhoneNumber = '';
        if(objRestaurantPhoneNumber !== undefined && Object.keys(objRestaurantPhoneNumber).length > 0){
            restaurantPhoneNumber = objRestaurantPhoneNumber.restaurant_phone_number;
        }
        msg = `Something is wrong with your internet signal please call the restaurant with your order ${restaurantPhoneNumber}`
        error = true;
    }
    return {error,msg};
}
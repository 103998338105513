import React from 'react'
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import RenderCarouselImages from './RenderCarouselImages';


const MenuCategory = (props) => {

    const renderMenuItemImages = () => (
        props.MenuItems ? 
            props.MenuItems.map(card=>(
                <RenderCarouselImages
                    key={card._id}
                    {...card}
                />
            ))
        :null
    )
    return (
        <div className={`card_item_wrapper `}>
            <div className="product-item d-block pl-2 pr-3 py-2 mb-3 cursor-pointer" onClick={()=> props.onClickMenuCategory(props)}>
                <div className="row">
                    <div className="col-4 col-lg-3 pr-0">
                        <Carousel
                            infinite={true}
                            slidesPerScroll={1}
                            animationSpeed={1500}
                            autoPlay={3000}
                        >
                            {renderMenuItemImages()}
                            {/* <div className="prod-img">
                                <img src={`https://s3.amazonaws.com/juzbi/menu-items/item-images/j07txkgkp82qcngosmsf-miprhj7y5wqld8xgkoa6p`} alt="products" className="mw-100" />
                            </div>
                            <div className="prod-img">
                                <img src={`https://s3.amazonaws.com/juzbi/menu-items/item-images/5anq7n8g2mbg3oe4sy8egf-76j4s4acznfuwl3mambng`} alt="products" className="mw-100" />
                            </div>
                            <div className="prod-img">
                                <img src={`https://s3.amazonaws.com/juzbi/menu-items/item-images/k4echkhecvlg2zmi7964f-fcoak11i689ed5cahisk`} alt="products" className="mw-100" />
                            </div> */}
                        </Carousel>
                        {/* <div className="prod-img">
                            <img src={`${S3_URL}${props.s3_image_path}`} alt="products" className="mw-100" />
                        </div> */}
                    </div>
                    <div className="col-8 col-lg-9">
                        <div className="prod-info">
                            <h4>{props.name}</h4>
                            {/* <p>
                                {props.description}
                            </p> */}
                            {/* <h4 className="text-right font-italic">$ {props.price}</h4> */}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )  
                
}
export default MenuCategory;



import {
    SAVE_ORDER,
    FETCH_USER_ORDERS,
    FETCH_ORDER_BY_ID,
    CLEAR_ORDER_HISTORY,
    FETCH_MAX_ORDER_TICKET_NUMBER,
} from '../actions/types';
 

export default function(state={},action){
    switch(action.type){
        
        case SAVE_ORDER:
            return {...state,orderSavedSuccess:action.payload};
            /* return {
                ...state,
                successBuy: action.payload.success,
                userData:{
                    ...state.userData,
                    cart: action.payload.cart
                },
                cartDetail: action.payload.cartDetail
            } */
        case FETCH_USER_ORDERS:
            return  {...state,
                        userOrders: action.payload.Orders,
                        total_user_orders: action.payload.total_user_orders
                    }
        case FETCH_ORDER_BY_ID:
            return  {...state,
                Order: action.payload.order,
                error: action.payload.error,
                msg: action.payload.msg,
            }
        case CLEAR_ORDER_HISTORY:
            return {...state, userOrders: action.payload,total_user_orders:0 }
        case FETCH_MAX_ORDER_TICKET_NUMBER:
            return {...state, maxTicketNumber: action.payload.ticket_no }
        default:
            return state;
    }
}
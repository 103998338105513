import {
    ADD_TO_CART_USER,
    GET_CART_ITEMS_USER,
    REMOVE_CART_ITEM_USER,
    REMOVE_CART_SUB_ITEM_USER,
    CLEAR_CART
} from '../actions/types';
 

/* export default function(state=[],action){
    switch(action.type){
        case ADD_TO_CART_USER:
            return {...state, 
                cart: action.payload
            }
        case GET_CART_ITEMS_USER:
            return {...state,cart: action.payload }
        case REMOVE_CART_ITEM_USER:
            return {
                ...state,
                cart: action.payload.cart
            }
        default:
            return state;
    }
} */
export default function(state=[],action){
    switch(action.type){
        case ADD_TO_CART_USER:
            return [...state, 
                action.payload
            ]
        case CLEAR_CART:
            return action.payload;
        case GET_CART_ITEMS_USER:
            return [...state,action.payload ]
        case REMOVE_CART_ITEM_USER:
            /* console.log('action.payload in REMOVE_CART_ITEM_USER')
            console.log(action.payload)
            console.log('state is')
            console.log(state) */
            // menu_item_id
            // timestamp
            // Removes the item from cart array without directly mutating the state.
            // The Array.prototype.filter() method prevents us from mutating the array

            const itemIndex = state.findIndex(i => i.menu_item_id === action.payload.menu_item_id &&  i.timestamp === action.payload.timestamp);
            // console.log('itemIndex')
            // console.log(itemIndex)
            // console.log('state.filter((item, index) => index !== itemIndex)')
            // console.log(state.filter((item, index) => index !== itemIndex))
            return state.filter((item, index) => index !== itemIndex);
            /* return [
                    ...state,
                    // action.payload
                ] */
        case REMOVE_CART_SUB_ITEM_USER:
            /* console.log('action.payload in REMOVE_CART_SUB_ITEM_USER')
            console.log(action.payload)
            console.log('state is')
            console.log(state) */
            let product = action.payload.product;
            let subProduct = action.payload.subProduct;
            /* return state.map((item, index) => {
                if(item.menu_item_id !== product.menu_item_id &&  item.timestamp !== product.timestamp) {
                    return item;
                }
                if(item.selectedComboItems.length > 0){
                    const subItemIndex = item.selectedComboItems.findIndex(i => i.selectedComboItemId === subProduct.selectedComboItemId);
                    // console.log('subItemIndex')
                    // console.log(subItemIndex)
                    // console.log('state.filter((item, index) => index !== subItemIndex)')
                    // console.log(state.filter((item, index) => index !== subItemIndex))
                    item.selectedComboItems = item.selectedComboItems.filter((item, index) => index !== subItemIndex);

                }else{
                    return item;
                }
                return [
                    ...state,
                    ...action.payload
                ]    
            }); */
            let items = state.slice();
            const itemIndexi = items.findIndex(item => item.menu_item_id === product.menu_item_id &&  item.timestamp === product.timestamp);
            if(items[itemIndexi].selectedComboItems.length > 0){
                const subItemIndex = items[itemIndexi].selectedComboItems.findIndex(i => i.selectedComboItemId === subProduct.selectedComboItemId);
                    // console.log('subItemIndex')
                    // console.log(subItemIndex)
                    // console.log('state.filter((item, index) => index !== subItemIndex)')
                    // console.log(state.filter((item, index) => index !== subItemIndex))
                let subItemPrice = (parseFloat(subProduct.ingredientTotal) +parseFloat(subProduct.combo_item_price)).toFixed(2)
                items[itemIndexi].totalPrice = (parseFloat(items[itemIndexi].totalPrice) - parseFloat(subItemPrice)).toFixed(2);  
                items[itemIndexi].selectedComboItems = items[itemIndexi].selectedComboItems.filter((item, index) => index !== subItemIndex);
            }
            // This finds the item that is already available in the cart array and updates 
            // the item's quantity without mutating directly the State
            // Always make a copy of the state and return a new state.hat will trigger a re-render
            // menu_item_id
            // timestamp
            // Removes the item from cart array without directly mutating the state.
            // The Array.prototype.filter() method prevents us from mutating the array

            // const itemIndex = state.findIndex(i => i.menu_item_id === action.payload.menu_item_id &&  i.timestamp === action.payload.timestamp);
            // console.log('itemIndex')
            // console.log(itemIndex)
            return state;
            // console.log('state.filter((item, index) => index !== itemIndex)')
            // console.log(state.filter((item, index) => index !== itemIndex))
            // return state.filter((item, index) => index !== itemIndex);
            /* return [
                    ...state,
                    // action.payload
                ] */
        default:
            return state;
    }
}
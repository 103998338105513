import React, { Fragment } from 'react';
import IngredientHeading from './IngredientHeading';
import LoadIngredientsItems from './LoadIngredientsItems';
const RenderComboItemsBox = (props) => {
    // console.log('props')
    // console.log('props')
    // console.log(props)
    const { MenuItemsIngredientsMultiple } = props;
    // console.log('MenuItemsIngredientsMultiple')
    // console.log(MenuItemsIngredientsMultiple)
    return (
        <Fragment>
            {MenuItemsIngredientsMultiple ?
                MenuItemsIngredientsMultiple.map(menuItem => {

                    if (menuItem._id.toString() === props.menu_item_id) {
                        return null;
                    } {
                        return <div className="row bg-grey product-bill item-block" key={menuItem._id}>
                            <div className="col-12 text-left font-weight-bolder text-uppercase">
                                <h5 className="item-heading">{menuItem.name}</h5>
                            </div>
                            <div className="col-6">
                                {/* <h4>Free Ingredients</h4> */}
                                <IngredientHeading
                                    list={props.menuItemFreeIngredients}
                                    menu_item_id={menuItem._id}
                                    type={'free'}
                                    MenuItemType={'combo'}
                                />
                                {/* {this.IngredientHeading(props.menuItemFreeIngredients,menuItem._id,'free','combo')} */}
                                <ul>
                                    <LoadIngredientsItems
                                        list={props.menuItemFreeIngredients}
                                        menuItemName={menuItem.name}
                                        menu_item_id={menuItem._id}
                                        type={"free"}
                                        MenuItemType={"combo"}
                                        handleIsCheckBoxChecked={props.handleIsCheckBoxChecked}
                                        handleOnClickCheckBox={(event) => props.handleOnClickCheckBox(event)}
                                    />
                                </ul>
                            </div>
                            <div className="col-6">
                                {/* <h4>Extra Charge</h4> */}
                                <IngredientHeading
                                    list={props.menuItemFreeIngredients}
                                    menu_item_id={menuItem._id}
                                    type={'paid'}
                                    MenuItemType={'combo'}
                                />
                                {/* {this.IngredientHeading(props.menuItemFreeIngredients,menuItem._id,'paid','combo')} */}
                                <ul>
                                    <LoadIngredientsItems
                                        list={props.menuItemFreeIngredients}
                                        menuItemName={menuItem.name}
                                        menu_item_id={menuItem._id}
                                        type={"paid"}
                                        MenuItemType={"combo"}
                                        handleIsCheckBoxChecked={props.handleIsCheckBoxChecked}
                                        handleOnClickCheckBox={(event) => props.handleOnClickCheckBox(event)}
                                    />
                                </ul>
                            </div>
                        </div>
                    }
                })
                : null}
        </Fragment>
    );
};

export default RenderComboItemsBox;
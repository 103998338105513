import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import LoadMoreMenuItems from './components/LoadMenuItems/LoadMoreMenuItems';
import LoadMoreMenuCategories from './components/LoadMenuCategories/LoadMoreMenuCategories';
import $ from 'jquery';
import { fetchMenuCatgories } from '../../../actions';
import { fetchMenuCatgoriesWithMenuItems } from '../../../actions/menu_category_actions';
import { getMenuItemsByCategoryId,clearMenuItems  } from '../../../actions/menu_actions';
import { withRouter } from 'react-router-dom';
// import { Spinner } from 'reactstrap';
import LoadingSpinner from '../../utils/LoadingSpinner';
import CartButton from '../../common/components/CartButton';
import dropLeftPng from '../../common/images/icons/drop-left.png';
import HomePageCardBarOptions from './components/HomePageCardBarOptions/HomePageCardBarOptions';
// import { socket } from '../../layout/Header';

class Home extends Component {

    state = {
        menuCategoryId:'',
        limit:10000,
        skip:0,
        selectedCategory:"",
        // selectedCategory:"Select Food Category",
        loading: true,
        loadingMenuCatgoeryItems: false,
    }

    componentWillUnmount(){
        this.props.clearMenuItems();
    }

    componentDidMount() {
        this.props.fetchMenuCatgoriesWithMenuItems().then(response =>{
            this.setState({loading:false})
        });
        this.props.fetchMenuCatgories().then(response =>{
            // this.setState({loading:false})
        });
        window.home = this;
    }
    constructor(props) {
        super(props)
        this._toggleDiv = this._toggleDiv.bind(this)
    }
      
    _toggleDiv() {
        // console.log('d')
        $(this.refs['drpMenuFoodUl']).fadeToggle(500);
    }
    onClickMenuCategory = (menuCategory) => {
        this.setState({loadingMenuCatgoeryItems:true});
        // console.log('menuCategory')
        // let skip = this.state.skip + this.state.limit;
        let menuCategoryId = menuCategory._id;
        let skip = 0;
        this.setState({
            selectedCategory:menuCategory.name
        })
        $(this.refs['drpMenuFoodUl']).fadeToggle(500);

        this.props.getMenuItemsByCategoryId(
            skip,
            this.state.limit,
            menuCategoryId,
        ).then(()=>{
            this.setState({
                loadingMenuCatgoeryItems:false,
                skip,
                menuCategoryId
            })
        })
    }
    handleOnClickBackButton = () => {
        this.setState({
            menuCategoryId:'',
            limit:10000,
            skip:0,
            selectedCategory:"",
            loading: false,
            loadingMenuCatgoeryItems: false,
        });
        this.props.clearMenuItems();
    }
    loadMoreMenuItemsF = () => {
        this.setState({loadingMenuCatgoeryItems:true});
        let skip = this.state.skip + this.state.limit;

        this.props.getMenuItemsByCategoryId(
            skip,
            this.state.limit,
            this.state.menuCategoryId,
            this.props.MenuItems.MenuItems
        ).then(()=>{
            this.setState({
                loadingMenuCatgoeryItems:false,
                skip
            })
        })
    }
    // console.log('this.props.menuCategories')
    // console.log(this.props.menuCategories)

    renderCategories() {
        return this.props.menuCategories.MenuCategories.map(menuCategory => {
            return (
                // <Fragment >
                    <li  key={menuCategory._id} onClick={this.onClickMenuCategory.bind(this,menuCategory)} >
                        <button  className="AvenirNextMedium d-flex align-items-center">
                            <i>{menuCategory.name}</i>
                        </button>
                        {/* <a href="#" className="AvenirNextMedium d-flex align-items-center">
                            <i>{menuCategory.name}</i>
                        </a> */}
                    </li>
                // </Fragment>
            );
        });
    }
    
    render() {
        if(this.state.loading){
            return (
                    <LoadingSpinner subClass={'mt10 mb10'} />
            )
        }
        const MenuItems = this.props.MenuItems;
        const MenuCategoriesWithMenuItems = this.props.menuCategories.MenuCategoriesWithMenuItems;
        
        return (
            <Fragment>
            <section className="container home-main">
                <HomePageCardBarOptions
                    userData={this.props.user.userData}
                />
                {
                    this.state.selectedCategory !== ''?
                    <header className="container inner-header">
                        <div className="row">
                            <div className="col-12 mb20">
                                <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={() => this.handleOnClickBackButton()} />
                            </div>
                        </div>
                    </header>
                    :null
                }
                <div className="row">
                    {/* <!-- product select --> */}
                    {
                        this.state.selectedCategory !== ''?
                            <div className="col-12 mb-3">
                                <div className="position-relative">
                                    <div className="food-ul-link position-relative AvenirNextMedium d-flex align-items-center cursor-pointer" onClick={this._toggleDiv}>
                                        <i>{this.state.selectedCategory}</i>
                                    </div>
                                    <ul className="food-ul position-absolute" ref="drpMenuFoodUl">
                                        {this.props.menuCategories.MenuCategories.map((menuCategory,i) => 
                                            <li  key={i} onClick={() => this.onClickMenuCategory(menuCategory)} >
                                                <div className="AvenirNextMedium d-flex align-items-center cursor-pointer">
                                                    <i>{menuCategory.name}</i>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                       :null
                    }
                    {/* <!-- product select --> */}
                    {
                        this.state.selectedCategory === ''?
                            <div className="col-12">
                                {
                                    MenuCategoriesWithMenuItems !== 'undefined' && MenuCategoriesWithMenuItems !== undefined ?
                                        <LoadMoreMenuCategories
                                            MenuCategories={MenuCategoriesWithMenuItems}
                                            onClickMenuCategory={this.onClickMenuCategory}
                                        />
                                    :null
                                }
                                {
                                    this.state.loading ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
                                }
                                
                            </div>
                        :null
                    }
                    
                    <div className="col-12">
                        {
                            MenuItems !== 'undefined' && MenuItems !== undefined ?
                                <LoadMoreMenuItems
                                    menuCategoryId={this.state.menuCategoryId}
                                    limit={this.state.limit}
                                    total_menu_items={MenuItems.total_menu_items}
                                    MenuItems={MenuItems.MenuItems}
                                    loadMore={()=> this.loadMoreMenuItemsF()}
                                />
                            :null
                        }
                        {
                            this.state.loadingMenuCatgoeryItems ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
                        }
                        
                    </div>
                    

                    {/* <!-- my cart button --> */}
                    <div className="col-12">
                        <div className="my-cart-btn">
                            <CartButton 
                                cart={this.props.cart}
                            />
                            
                        </div>
                    </div>
                    {/* <!-- my cart button --> */}

                </div>
                    
            </section>
            </Fragment>
                        
        )
    }
}

function mapStateToProps(state) {
    // console.log('state')
    // console.log(state)
    return { menuCategories : state.menuCategories,MenuItems : state.MenuItems};
}

// export default Home
export default connect(mapStateToProps, { fetchMenuCatgories,fetchMenuCatgoriesWithMenuItems,getMenuItemsByCategoryId,clearMenuItems })(withRouter(Home));

import { toast } from 'react-toastify';
import moment from 'moment';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function showMsg(msg,type) {
    if(type === 'success'){
        toast.success(msg);
    }else if(type === 'error'){
        toast.error(msg);
    }
    else if(type === 'info'){
        toast.info(msg);
    }
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    // let timestamp = new Date().getUTCMilliseconds();
    // return timestamp + Math.random().toString(36).substr(2, 9);
}

export function getUniqueName() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    let timestamp = new Date().getUTCMilliseconds();
    return timestamp + Math.random().toString(36).substr(2, 9);
}

export function getUniqueNameWithTimeStamp() {
    let timestamp = new Date().getUTCMilliseconds();
    return (timestamp + Math.random().toString(36).substring(2, 15)) + Math.random().toString(36).substring(2, 15);
}


export function timeAgo(date) {
    moment.locale('en', {
        relativeTime : {
            future: "%s ago",
        }
    });
    /*moment.fromNow = function (a) {
        if (Math.abs(moment().diff(this)) < 25000) { // 25 seconds before or after now
            return 'a few seconds ago';
        }
        return this.fromNow(a);
    }*/
  var output = new Date(date*1000);
  output = moment(output).fromNow();
  return output;
}
export function formatOrderDate(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("Do MMMM YYYY"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}
export function formatOrderDateNumber(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("DD-MM-YYYY kk:mm"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}
export function calculateOrderMenuItemTotal(order) {
    let tax = parseFloat(order.tax_per/100).toFixed(2);
    
    let total = 0;
    let total_amount = 0.00;
    if(order.order_details.length > 0 ){

        for (let index = 0; index < order.order_details.length; index++) {
            const element = order.order_details[index];
            total = parseFloat(parseFloat(total) + parseFloat(element.totalPrice)).toFixed(2);
        }
        let tax_amount = ((total) * (tax)).toFixed(2);
        total_amount = (parseFloat(total) + parseFloat(tax_amount)).toFixed(2);
        
        
    }
    // console.log(order.order_no)
    // console.log('total')
    // console.log(total)
    // console.log('total_amount')
    // console.log(total_amount)
    return total_amount;
}

export function addTimeToCurrentDateTime(time_in_mins) {
    /* console.log('time_in_mins')
    console.log('time_in_mins')
    console.log('time_in_mins')
    console.log(time_in_mins) */
    // let date = new Date(datetime);
    // let formated_date = moment(datetime).format("MMM D YYYY"); 
    var dt = new Date();
    /* console.log('dt before')
    console.log('dt before')
    console.log('dt before')
    console.log(dt)
    console.log('dt.getMinutes()')
    console.log(dt.getMinutes()) */
    dt.setMinutes( dt.getMinutes() + parseInt(time_in_mins) );
    /* console.log('dt after')
    console.log('dt after')
    console.log('dt after')
    console.log(dt) */
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return dt;
}

export function formatExistingOrderDateTimeOnly(datetime) {
    // let date = new Date(datetime);
    let formated_date = moment(datetime).format("hh:mm A"); 
    // formated_date = date.getDate() + '-' + (date.getMonth()+1) + '-' +  date.getFullYear();
    return formated_date;
}

function getDistanceInKiloToBeRemoved(delivery_fees){
    let distance_in_kilo_to_be_removed = 0;
    let distance_in_kilo_to_be_removed_price = 0;
    // if(delivery_fees.extra_charge_per_km_after.price !== ''){
    //     distance_in_kilo_to_be_removed = 15;
    // }else
    if(delivery_fees.fourtheen_to_fifteen.price !== ''){
        console.log('i am in fourtheen_to_fifteen')
        distance_in_kilo_to_be_removed = 15;
        distance_in_kilo_to_be_removed_price = delivery_fees.fourtheen_to_fifteen.price;
    }else if(delivery_fees.thirteen_to_fourtheen.price !== ''){
        console.log('i am in thirteen_to_fourtheen')
        distance_in_kilo_to_be_removed = 14;
        distance_in_kilo_to_be_removed_price = delivery_fees.thirteen_to_fourtheen.price;
    }else if(delivery_fees.twelve_to_thirteen.price !== ''){
        console.log('i am in twelve_to_thirteen')
        distance_in_kilo_to_be_removed = 13;
        distance_in_kilo_to_be_removed_price = delivery_fees.twelve_to_thirteen.price;
    }else if(delivery_fees.eleven_to_twelve.price !== ''){
        console.log('i am in eleven_to_twelve')
        distance_in_kilo_to_be_removed = 12;
        distance_in_kilo_to_be_removed_price = delivery_fees.eleven_to_twelve.price;
    }else if(delivery_fees.ten_to_eleven.price !== ''){
        console.log('i am in ten_to_eleven')
        distance_in_kilo_to_be_removed = 11;
        distance_in_kilo_to_be_removed_price = delivery_fees.ten_to_eleven.price;
    }else if(delivery_fees.nine_to_ten.price !== ''){
        console.log('i am in nine_to_ten')
        distance_in_kilo_to_be_removed = 10;
        distance_in_kilo_to_be_removed_price = delivery_fees.nine_to_ten.price;
    }else if(delivery_fees.eight_to_nine.price !== ''){
        console.log('i am in eight_to_nine')
        distance_in_kilo_to_be_removed = 9;
        distance_in_kilo_to_be_removed_price = delivery_fees.eight_to_nine.price;
    }else if(delivery_fees.seven_to_eight.price !== ''){
        console.log('i am in seven_to_eight')
        distance_in_kilo_to_be_removed = 8;
        distance_in_kilo_to_be_removed_price = delivery_fees.seven_to_eight.price;
    }else if(delivery_fees.six_to_seven.price !== ''){
        console.log('i am in six_to_seven')
        distance_in_kilo_to_be_removed = 7;
        distance_in_kilo_to_be_removed_price = delivery_fees.six_to_seven.price;
    }else if(delivery_fees.five_to_six.price !== ''){
        console.log('i am in five_to_six')
        distance_in_kilo_to_be_removed = 6;
        distance_in_kilo_to_be_removed_price = delivery_fees.five_to_six.price;
    }else if(delivery_fees.four_to_five.price !== ''){
        console.log('i am in four_to_five')
        distance_in_kilo_to_be_removed = 5;
        distance_in_kilo_to_be_removed_price = delivery_fees.four_to_five.price;
    }else if(delivery_fees.three_to_four.price !== ''){
        console.log('i am in three_to_four')
        distance_in_kilo_to_be_removed = 4;
        distance_in_kilo_to_be_removed_price = delivery_fees.three_to_four.price;
    }else if(delivery_fees.two_to_three.price !== ''){
        console.log('i am in two_to_three')
        distance_in_kilo_to_be_removed = 3;
        distance_in_kilo_to_be_removed_price = delivery_fees.two_to_three.price;
    }else if(delivery_fees.one_to_two.price !== ''){
        console.log('i am in one_to_two')
        distance_in_kilo_to_be_removed = 2;
        distance_in_kilo_to_be_removed_price = delivery_fees.one_to_two.price;
    }else{
        console.log('i am in else')
        distance_in_kilo_to_be_removed = 0;
        distance_in_kilo_to_be_removed_price =0;
    }
    return {distance_in_kilo_to_be_removed,distance_in_kilo_to_be_removed_price};
}

export function getDeliveryAmount(delivery_amount,delivery_fees,distance_in_kilo) {
    if(distance_in_kilo > 0 && distance_in_kilo<= 1){
        delivery_amount = delivery_fees.zeor_to_one.price;
    }else if(distance_in_kilo > 1 && distance_in_kilo<= 2){
        delivery_amount = delivery_fees.one_to_two.price;
    }else if(distance_in_kilo > 2 && distance_in_kilo<= 3){
        delivery_amount = delivery_fees.two_to_three.price;
    }else if(distance_in_kilo > 3 && distance_in_kilo<= 4){
        delivery_amount = delivery_fees.three_to_four.price;
    }else if(distance_in_kilo > 4 && distance_in_kilo<= 5){
        delivery_amount = delivery_fees.four_to_five.price;
    }else if(distance_in_kilo > 5 && distance_in_kilo<= 6){
        delivery_amount = delivery_fees.five_to_six.price;
    }else if(distance_in_kilo > 6 && distance_in_kilo<= 7){
        delivery_amount = delivery_fees.six_to_seven.price;
    }else if(distance_in_kilo > 7 && distance_in_kilo<= 8){
        delivery_amount = delivery_fees.seven_to_eight.price;
    }else if(distance_in_kilo > 8 && distance_in_kilo<= 9){
        delivery_amount = delivery_fees.eight_to_nine.price;
    }else if(distance_in_kilo > 9 && distance_in_kilo<= 10){
        delivery_amount = delivery_fees.nine_to_ten.price;
    }else if(distance_in_kilo > 10 && distance_in_kilo<= 11){
        delivery_amount = delivery_fees.ten_to_eleven.price;
    }else if(distance_in_kilo > 11 && distance_in_kilo<= 12){
        delivery_amount = delivery_fees.eleven_to_twelve.price;
    }else if(distance_in_kilo > 12 && distance_in_kilo<= 13){
        delivery_amount = delivery_fees.twelve_to_thirteen.price;
    }else if(distance_in_kilo > 13 && distance_in_kilo<= 14){
        delivery_amount = delivery_fees.thirteen_to_fourtheen.price;
    }else if(distance_in_kilo > 14 && distance_in_kilo<= 15){
        delivery_amount = delivery_fees.fourtheen_to_fifteen.price;
    }else if(distance_in_kilo >= 15){
        
        console.log('i am in distance_in_kilo >= 15')
        // console.log(delivery_fees.fourtheen_to_fifteen.price)
        let price = 0;
        if(delivery_fees.fourtheen_to_fifteen.price !== ''){
            price = delivery_fees.fourtheen_to_fifteen.price;
        }else if(delivery_fees.thirteen_to_fourtheen.price !== ''){
            price = delivery_fees.thirteen_to_fourtheen.price;
        }else if(delivery_fees.twelve_to_thirteen.price !== ''){
            price = delivery_fees.twelve_to_thirteen.price;
        }else if(delivery_fees.eleven_to_twelve.price !== ''){
            price = delivery_fees.eleven_to_twelve.price;
        }else if(delivery_fees.ten_to_eleven.price !== ''){
            price = delivery_fees.ten_to_eleven.price;
        }else if(delivery_fees.nine_to_ten.price !== ''){
            price = delivery_fees.nine_to_ten.price;
        }else if(delivery_fees.eight_to_nine.price !== ''){
            price = delivery_fees.eight_to_nine.price;
        }else if(delivery_fees.seven_to_eight.price !== ''){
            price = delivery_fees.seven_to_eight.price;
        }else if(delivery_fees.six_to_seven.price !== ''){
            price = delivery_fees.six_to_seven.price;
        }else if(delivery_fees.five_to_six.price !== ''){
            price = delivery_fees.five_to_six.price;
        }else if(delivery_fees.four_to_five.price !== ''){
            price = delivery_fees.four_to_five.price;
        }else if(delivery_fees.three_to_four.price !== ''){
            price = delivery_fees.three_to_four.price;
        }else if(delivery_fees.two_to_three.price !== ''){
            price = delivery_fees.two_to_three.price;
        }else if(delivery_fees.one_to_two.price !== ''){
            price = delivery_fees.one_to_two.price;
        }else{
            price = 0;
        }
        if(distance_in_kilo > 15 && delivery_fees.extra_charge_per_km_after.price !== ''){
            price = delivery_fees.extra_charge_per_km_after.price;
        }

        // if(delivery_fees.extra_charge_per_km_after.price !== ''){
        //     price = delivery_fees.extra_charge_per_km_after.price;
        // }
        console.log('price')
        console.log('price')
        console.log(price);
        let {distance_in_kilo_to_be_removed,distance_in_kilo_to_be_removed_price} = getDistanceInKiloToBeRemoved(delivery_fees);
        console.log('distance_in_kilo_to_be_removed')
        console.log('distance_in_kilo_to_be_removed')
        console.log(distance_in_kilo_to_be_removed);
        console.log('distance_in_kilo_to_be_removed_price')
        console.log('distance_in_kilo_to_be_removed_price')
        console.log(distance_in_kilo_to_be_removed_price);
        let calculate_over_distance = parseFloat(parseFloat(distance_in_kilo) - parseFloat(distance_in_kilo_to_be_removed)).toFixed(2);
        // let calculate_over_distance = parseFloat(parseFloat(distance_in_kilo) - 15).toFixed(2);
        console.log('calculate_over_distance')
        console.log('calculate_over_distance')
        console.log(calculate_over_distance);
        if(price === 0){
            delivery_amount = 0;
        }else{
            delivery_amount = parseFloat((parseFloat(price))*parseFloat(calculate_over_distance)).toFixed(2);
            delivery_amount = parseFloat(parseFloat(delivery_amount)+ parseFloat(distance_in_kilo_to_be_removed_price)).toFixed(2);
            // delivery_amount = parseFloat(parseFloat(price)+parseFloat(calculate_over_distance)).toFixed(2);
        }
        // delivery_amount = parseFloat(9.00+parseFloat(calculate_over_distance)).toFixed(2);
    }
    if(delivery_amount === ''){
        delivery_amount = 0;
    }

    return delivery_amount;
}
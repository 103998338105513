import React from 'react';
const IngredientHeading = (props) => {
    const {list,menu_item_id,type,MenuItemType} = props;
    if(list && list.length > 0 ){
        let items = []

        if(type === 'free'){
            items = list.filter(m => m.menu_item_id===menu_item_id && (m.ingredientType === 'included' || m.ingredientType === 'optional_free'));
        }else if(type === 'paid'){
            items = list.filter(m => m.menu_item_id===menu_item_id && (m.ingredientType === 'extras_charged'));
        }
        /* console.log('menu_item_id')
        console.log(menu_item_id)
        console.log('items')
        console.log(items) */
        if(items.length > 0){
            if(type === 'free'){
                return <h4 className="FreeIngredientsHeading">Free Ingredients</h4>;
            }else if(type === 'paid'){
                return <h4 className="PaidIngredientsHeading">Extra Charge</h4>
            }
        }else{
            return <h4></h4>; 
        }
    }else{
        return <h4></h4>;
    }
    // return (
    //     <Fragment>
               
    //     </Fragment>
    // );
};

export default IngredientHeading;
import axios from 'axios';
import {
    SAVE_ORDER,
    FETCH_USER_ORDERS,
    FETCH_ORDER_BY_ID,
    CLEAR_ORDER_HISTORY,
    FETCH_MAX_ORDER_TICKET_NUMBER
} from './types';

import { ORDER_SERVER, POS_ORDER_SERVER } from '../components/utils/misc';
import { BASE_URL } from '../components/utils/misc';
export const submitCreateOrder = (values) => async dispatch => {
    const res = await axios.post(`${ORDER_SERVER}/save-order`, values);
    if(res.data.loginSuccess){
        axios.defaults.headers.common['Authorization'] = res.data.token;
        localStorage.setItem('token', res.data.token)
    }
    // history.push('/surveys');
    dispatch({ type: SAVE_ORDER, payload: res.data });
};
export const fetchUserOrders = (user_id,skip, limit, previousState = [],created_from ='web') => async dispatch => {
    const data = {
        user_id,
        limit,
        skip,
        created_from
    }
    const response = await axios.post(`${ORDER_SERVER}/fetch-user-orders-by-uid`,data);
    let newState = [
        ...previousState,
        ...response.data.Orders
    ];
    let res = {
        total_user_orders: response.data.total_user_orders,
        Orders: newState
    }
    console.log('res')
    console.log(res)
    dispatch({ type: FETCH_USER_ORDERS, payload: res });
};

export const clearOrderHistory = () => async dispatch => {
    dispatch({ type: CLEAR_ORDER_HISTORY, payload: '' });
};

export const fetchOrderByOId = (order_id) => async dispatch => {
    const res = await axios.get(`${BASE_URL}/api/fetch-order-by-oid?id=${order_id}`);
    
    dispatch({ type: FETCH_ORDER_BY_ID, payload: res.data });
};

export const fetchMaxTicketNumberOfOrder = () => async dispatch => {
    const res = await axios.get(`${BASE_URL}${POS_ORDER_SERVER}/fetch-max-ticket-number-order`);
    
    dispatch({ type: FETCH_MAX_ORDER_TICKET_NUMBER, payload: res.data });
};

import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingSpinner = ({subClass}) => {
    // console.log(subClass)
    // console.log(subClass)
    return (
        <div className={`main_loader ${subClass}`}>
        {/* <div className="main_loader"> */}
            <Spinner color="primary" />
            {/* <CircularProgress style={{color:'#2196F3'}} thickness={7}/>  */}
        </div>
    );
};

export default LoadingSpinner;
import React from 'react';
import MenuCategory from './MenuCategory';

const MenuCategoryBlock = (props) => {

    const renderCards = () => (
        props.list ? 
            props.list.map(card=>(
                <MenuCategory
                    key={card._id}
                    onClickMenuCategory={props.onClickMenuCategory}
                    {...card}
                />
            ))
        :null
    )

    return (
        <div className="card_block_shop">
            <div>
                <div>
                    {props.list ?
                        props.list.length === 0 ?
                            <div className="no_result">
                                Sorry, no results
                            </div>
                        :null
                    :null}
                    { renderCards(props.list)}
                </div>

            </div>
        </div>
    );
};

export default MenuCategoryBlock;
import React from 'react';
// import { Link } from 'react-router-dom';

const IngredientsCombosLi = (props) => {
    // console.log('props in IngredientsCombosLi')
    // console.log('props in IngredientsCombosLi')
    // console.log('props in IngredientsCombosLi')
    // console.log(props)

    // const {menuItem} = props;
    // let {radioLiImg, linkhref, linkTitle} = this.props;
    return(
        <li>
            With {props.name}
            {/* With {menuItem.name} */}
            {/* <img src={radioLiImg} alt="" /> */}
            {/* <Link to={linkhref} >{linkTitle}</Link>  */}
        </li>
    );
        
};



export default IngredientsCombosLi

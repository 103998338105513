import {
    FETCH_RESTURANT_TAX,
    FETCH_RESTURANT_DELIVERY_AVAILABILITY,
    FETCH_RESTURANT_DELIVERY_FEES,
    CHECK_IF_RESTAURANT_IS_OPEN,
    FETCH_RESTURANT_PHONE_NUMBER,
    FETCH_RESTURANT_ADDRESS,
    FETCH_RESTAURANT_LOGO,
    FETCH_RESTAURANT_STRIPE_API_KEYS,
    FETCH_RESTAURANT_PROVINCE,
    FETCH_RESTAURANT_CARD_PROCESSING_FEE,
} from '../actions/types';
 

export default function(state={},action){
    switch(action.type){
        case FETCH_RESTURANT_TAX:
            // console.log('action')
            // console.log(action)
            return {...state, objtax: action.payload }
        case FETCH_RESTURANT_DELIVERY_AVAILABILITY:
            // console.log('action')
            // console.log(action)
            return {...state, objResturantDeliveryAvailability: action.payload }
        case FETCH_RESTURANT_DELIVERY_FEES:
            return {...state, objRestaurantDeliveryFees: action.payload }
        case CHECK_IF_RESTAURANT_IS_OPEN:
            return {...state, isRestaurantIsOpen: action.payload }
        case FETCH_RESTURANT_PHONE_NUMBER:
            return {...state, objRestaurantPhoneNumber: action.payload }
        case FETCH_RESTURANT_ADDRESS:
            return {...state, objRestaurantAddress: action.payload }
        case FETCH_RESTAURANT_LOGO:
            return {...state, objRestaurantLogo: action.payload }
        case FETCH_RESTAURANT_STRIPE_API_KEYS:
            return {...state, objRestaurantStripeApiKeys: action.payload }
        case FETCH_RESTAURANT_PROVINCE:
            return {...state, objRestaurantProvince: action.payload }
        case FETCH_RESTAURANT_CARD_PROCESSING_FEE:
            return {...state, objRestaurantCardProcessingFee: action.payload }
        default:
            return state;
    }
}
import React from 'react';
const RenderIngredients = (ingredients)  => {
    // console.log('product')
    // console.log(product.name)
    // console.log('ingredients')
    // console.log(ingredients)
    // console.log(ingredients.length) 
    // ingredients = ingredients.filter(i => i.is_checked === true);
    // console.log('ingredients after')
    // console.log(ingredients)
    if(ingredients.length > 0){
        // let rowLen =ingredients.length;
        // return images[0].url
        let ret = ingredients.map((ingredient,index) => {
            // console.log('ingredient.name')
            // console.log(ingredient.name)
            /* if (owned_game.game_id === game.game_id) {
              return renderOwned
            } else {
              return renderPrice
            } */
            /* if (rowLen === index + 1) {
                // last one
                return <li key={index}> {ingredient.name} </li>;
            } else { */
                // not last one
                if(ingredient.ingredientType === "extras_charged" && ingredient.is_checked === true){
                    return <li key={index}> Add {ingredient.name} <span className="float-right">${parseFloat(ingredient.price).toFixed(2)}</span> </li>;
                }else if(ingredient.ingredientType === "included" && ingredient.is_checked === false){
                    return <li key={index}> No {ingredient.name}  </li>;
                }else if(ingredient.ingredientType === "both" && ingredient.is_checked === false){
                    return <li key={index}> No {ingredient.name}  </li>;
                }else if(ingredient.ingredientType === "optional_free" && ingredient.is_checked === true){
                    return <li key={index}> Add {ingredient.name}  </li>;
                }
                
                // return <span key={index}> {ingredient.name} , </span>;
            // }
            
        })
        return ret;
        
    } else {
        // return '/images/image_not_availble.png'
        return null;
    }
}

export default RenderIngredients;
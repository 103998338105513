import React, { Fragment } from 'react'

export const CheckBox = props => {
    console.log('props.checked')
    console.log(props.checked)
    const handleClick = event => {
        event.preventDefault();
    }
    const handleOnChange = (id) =>{
        console.log('handleOnChange id')
        console.log('handleOnChange id')
        console.log('handleOnChange id')
        console.log(id)
        props.handleCheckChieldElement(id)
    }
    return (
        <Fragment>
            {/* <label className="checkbox"><input key={props.id}  type="checkbox" checked={props.isChecked} value={props.value} /><span className="checkmark"></span></label> */}
            {/* <label className="checkbox"><input key={props.id} onClick={props.handleCheckChieldElement} type="checkbox" checked={props.isChecked} value={props.value} /><span className="checkmark"></span></label> */}
            {/* <label className="checkbox"><input key={props.id}  type="checkbox" checked={props.isChecked} value={props.value} /><span className="checkmark"></span></label> */}
            {/* <label className="checkbox"><input key={props.id}  type="checkbox" checked={props.checked} value={props.value}  onClick={()=> props.handleCheckChieldElement(props._id)}  /><span className="checkmark"></span></label> */}
            <label className="checkbox" htmlFor={'chk' + props.id}><input id={'chk' + props.id}  key={props.id}  type="checkbox" checked={props.checked} value={props.value} onChange={()=>  handleOnChange(props.id)} onClick={handleClick }  /><span className="checkmark"></span></label>
            {/* <label className="checkbox"><input key={props.id}  type="checkbox" checked={props.isChecked} value={props.value} /><span className="checkmark"></span></label> */}
        </Fragment>
    )
}

export default CheckBox
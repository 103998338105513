import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AuthModalBody from './AuthModalBody';
class AuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    // this.setState(prevState => ({
    //   modal: !prevState.modal
    // }));
    this.props.handleOnChangeAuthModalToggle(!this.props.showAuthModal)
  }
  handleOnClickBtnAuthType = (selectedAuthType) => {
    console.log('selectedAuthType')
    console.log(selectedAuthType)
    this.toggle();
    this.props.handleOnClickBtnAuthType(selectedAuthType);
    // console.log('this.state.result + button')
    // console.log(this.state.result + button)
    
  }

  render() {
    // let isCustomBtn = this.props.isCustomBtn;
    // let btn = <Button color="danger" onClick={this.toggle}>{this.props.buttonLabel}</Button>;
    // if(isCustomBtn){
    //   btn = <button className={this.props.customBtnClasses} onClick={this.toggle}>{this.props.buttonLabel}</button>;
    // }
    return (
      <div>
        {/* <Button color="danger" onClick={this.toggle}>{this.props.buttonLabel}</Button> */}
        {/* {btn} */}
        <Modal isOpen={this.props.showAuthModal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Please Login or Create Account</ModalHeader>
          <ModalBody>
            <AuthModalBody 
              handleOnClickBtnAuthType={this.handleOnClickBtnAuthType}
            />
            {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. */}
          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

export default AuthModal;
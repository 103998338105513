import React from 'react'

export const IngredientCheckbox = props => {
    // console.log('props')
    // console.log(props)
    return (
        <li>
            <label className="checkbox">{props.name}
                <input data-menuitemtype={props.MenuItemType} data-menu_item_id={props.menu_item_id} data-menuitemname={props.menuItemName} data-name={props.name} data-price={props.price} data-ingredienttype={props.ingredienttype} key={props._id} onChange={props.handleOnClickCheckBox} type="checkbox" checked={props.isChecked} value={props.value} /><span className="checkmark"></span>
            </label>
        </li>
    )
}

export default IngredientCheckbox
import React from 'react';
// import trashRedPng from '../../../common/images/icons/trash-red.png';
import OrderReviewCartItemSubBlock from './OrderReviewCartItemSubBlock.js' 
import RenderIngredients from './RenderIngredients';
// import { Link } from 'react-router-dom';
const OrderReviewCartItemBlock = ({products}) => {

  

    const renderItems = () => (
        products ?
            products.map(product=>(
                <div className="row bg-grey product-bill" key={product.timestamp}>
                    
                        <div className="col-8">
                            <h4>{product.is_size_selected ? `${product.size_name} ` : null}{product.is_special_modifier_selected ? `${product.special_modifier_name} ` : null}{product.name}</h4>
                        </div>
                        <div className="col-4 text-right pl0">
                            <h4 className="pricing-tag">
                                ${(parseFloat(product.price) + parseFloat(product.ingredientTotal)).toFixed(2) }
                                {/* <img src={trashRedPng} alt="trash" className="mw-100 cursor-pointer trash-icon"  onClick={()=> removeItem(product)}  /> */}
                            </h4>
                            
                            {/* <h4 className="pricing-tag">$ {(parseFloat(product.price)).toFixed(2) }</h4> */}
                        </div>
                        <div className="col-12 pl20">
                            <ul>
                                {
                                    product.isShowIngredientsList ? RenderIngredients(product.ingredients) : null
                                    
                                }
                            </ul>
                            
                        </div>
                        
                        <OrderReviewCartItemSubBlock
                            mainProduct={product}
                            products={product.selectedComboItems}
                            type="cart"
                        />
                        
                        
                </div>
            ))

        :null
    )


    return (
        <div>
            {products ?
                        products.length === 0 ?
                            <div className="text-center mt20 mb20">
                                <h3>The cart is empty</h3>
                            </div>
                        :null
                    :null}
            {renderItems()}
        </div>
    );
};

export default OrderReviewCartItemBlock;
import React, { Fragment }  from 'react';
import { Link } from 'react-router-dom';
import rightArrowRedPng from '../../../common/images/icons/right-arrow-red.png';
import {formatOrderDate} from '../../../utils/general';
import { calculateFinalTotalOfOrder } from '../../../utils/OrdersPages/OrdersHelpers';
const OrderItem = (props) => {
    return (
        <Link to={`/order-details/${props._id}`}>
            <div className="row bg-grey product-bill">
                <div className="col-8">
                    <h4>Ticket Number {props.ticket_no} </h4>
                </div>
                <div className="col-4 text-right">
                    {/* <h4 className="pricing-tag">${parseFloat(props.final_total).toFixed(2)}</h4> */}
                    <h4 className="pricing-tag">
                        {
                            props.is_created_before_card_processing_fee === false ?
                            <Fragment> ${calculateFinalTotalOfOrder(props,props.delivery_payment_method,props.card_processing_fee)}</Fragment>
                            :<Fragment> ${parseFloat(props.total_amount_after_tax_delivery_charges).toFixed(2)}</Fragment>
                        }
                    </h4>
                </div>
                <div className="col-10">
                    <p>{formatOrderDate(props.createdAt)}</p>
                </div>
                <div className="col-2 mt-3 d-flex align-items-end justify-content-end">
                    <img src={rightArrowRedPng} alt="arrow" className="mw-100" />
                </div>
            </div>
        </Link>
    );
}



export default OrderItem;
import axios from 'axios';
import {
    ADD_TO_CART_USER,
    GET_CART_ITEMS_USER,
    REMOVE_CART_ITEM_USER,
    REMOVE_CART_SUB_ITEM_USER,
    CLEAR_CART
} from './types';


export const clearCart = () => async dispatch => {
    dispatch({ type: CLEAR_CART, payload: [] });
};

export const addToCart = (product) => async dispatch => {
    /* console.log('selectedCstateomboItems in meny action')
    console.log(selectedCstateomboItems)
    const data = {
        list:selectedCstateomboItems
    }
    const res = await axios.post(`/api/fetch-multiple-menu-items`,data); */
    
    // dispatch({ type: ADD_TO_CART_USER, payload: res.data });
    dispatch({ type: ADD_TO_CART_USER, payload: product });
};



export function addToCart_old(_id){

    const request = axios.post( `/addToCart?productId=${_id}`)
    .then(response => response.data)

    return {
        type: ADD_TO_CART_USER,
        payload: request
    }
}

export function getCartItems(cartItems, userCart){

    const request = axios.get(`/articles_by_id?id=${cartItems}&type=array`)
                    .then(response => {
     
                        userCart.forEach(item=>{
                            response.data.forEach((k,i)=>{
                                if(item.id === k._id){
                                    response.data[i].quantity = item.quantity;
                                }
                            })
                        })
                        return response.data;
                    })
                 

    return {
        type: GET_CART_ITEMS_USER,
        payload: request
    }

}



export const removeCartItem = (product) => async dispatch => {
    dispatch({ type: REMOVE_CART_ITEM_USER, payload: product });
};

export const removeCartSubItem = (product,subProduct) => async dispatch => {
    dispatch({ type: REMOVE_CART_SUB_ITEM_USER, payload: {product,subProduct} });
};

export function removeCartItem_old(id){

    const request = axios.get(`/removeFromCart?_id=${id}`)
                    .then(response => {

                        response.data.cart.forEach(item=>{
                            response.data.cartDetail.forEach((k,i)=>{
                                if(item.id === k._id){
                                    response.data.cartDetail[i].quantity = item.quantity;
                                }
                            })
                        })
                            return response.data;
                    })

    return {
        type: REMOVE_CART_ITEM_USER,
        payload: request
    }

}

import React from 'react';
import MenuCategoryBlock from './MenuCategoryBlock'; 

const LoadMoreMenuCategories = (props) => {
    return (
        <div className="MenuCategoriesWrapper">
            <div>
                <MenuCategoryBlock
                    list={props.MenuCategories}
                    onClickMenuCategory={props.onClickMenuCategory}
                    // menuCategoryId={props.menuCategoryId}
                />
            </div>
    
        </div>
    );
};

export default LoadMoreMenuCategories;
import { FETCH_MENU_CATEGORIES, FETCH_MENU_CATEGORIES_WITH_MENU_ITEMS } from '../actions/types';

export default function (state = { MenuCategories: [] }, action) {
  switch (action.type) {
    case FETCH_MENU_CATEGORIES:
      return {
        ...state,
        MenuCategories: action.payload.MenuCategories,
        total_menu_categories: action.payload.total_menu_categories,
      };
    case FETCH_MENU_CATEGORIES_WITH_MENU_ITEMS:
      return {
        ...state,
        MenuCategoriesWithMenuItems: action.payload.MenuCategoriesWithMenuItems,
        total_menu_categories_with_menu_items: action.payload.total_menu_categories_with_menu_items,
      };
    default:
      return state;
  }
}

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import DeliveryModalBody from './DeliveryModalBody';
import Script from 'react-load-script';
const DeliveryModal = (props) => {
  const {
    // buttonLabel,
    className,
    showDeliveryPickupModal,
    handleOnChangeDeliveryModalToggle
  } = props;

  //   const [modal, setModal] = useState(false);

  const toggle = () => {
    handleOnChangeDeliveryModalToggle(!showDeliveryPickupModal)
  };
  //   const toggle = () => setModal(!modal);

  return (
    <div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
        // url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCrFkMTeLoKX12qqIOMjscXDfv54dIEXuk&libraries=places"
        onLoad={props.handleScriptLoad}
      />
      <Modal onOpened={props.handleScriptLoad} isOpen={showDeliveryPickupModal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <DeliveryModalBody
            initialValues={props.initialValues}
            onSubmitForm={props.onSubmitForm}
            isCustomerFormSavingLoader={props.isCustomerFormSavingLoader}
            inputAddressChangedHandler={props.inputAddressChangedHandler}
            isCalculatingDeliveryFee={props.isCalculatingDeliveryFee}
            delivery_type={props.delivery_type}
            delivery_amount={props.delivery_amount}
            query={props.query}
          />
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter> */}
      </Modal>
    </div>
  );
}

export default DeliveryModal;
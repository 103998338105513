import React, { Component, Fragment } from 'react'
// import FormField from '../../utils/Form/formfield';
// import { update, generateData, isFormValid } from '../../utils/Form/formActions';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { loginUser } from '../../../actions/user_actions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const initialValuesSchema = {
    email: '',
    password: '',
}
const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please provide a valid email')
        .required('Email is Required'),
    password: Yup.string()
        .min(8, 'Password Too Short!')
        .max(50, 'Password Too Long!')
        .required('Password is Required'),
});
class Login extends Component {
    state = {
        isloading: false,
        formError: false,
        formSuccess:'',
        
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    

    onSubmitForm = async (fields) => {
        console.log('fields in submit')
        console.log(fields)
        if (this._isMounted) {
            this.setState({
                formError: false,
                isloading:true,
            })
        }
        this.setState({isloading:true})
        this.props.dispatch(loginUser(fields)).then(response =>{
            if(response.payload.loginSuccess){
                // console.log(response.payload);
                axios.defaults.headers.common['Authorization'] = response.payload.token;
                localStorage.setItem('token', response.payload.token);
                let is_checkout_redirect = this.props.match.params.is_checkout_redirect;
                let delivery_type = this.props.match.params.delivery_type;
                console.log('is_checkout_redirect')
                console.log('is_checkout_redirect')
                console.log(is_checkout_redirect)
                console.log('delivery_type')
                console.log('delivery_type')
                console.log(delivery_type)
                if((is_checkout_redirect === undefined || is_checkout_redirect === 'undefined') || (delivery_type === undefined || delivery_type === 'undefined')){
                    this.props.history.push('/');
                }else{
                    this.props.history.push(`/address-details/${delivery_type}`);
                }
            }else{
                this.setState({
                    formError: true,
                    isloading:false,
                })
            }
        });

        
    }

    // componentDidMount() {
    //     let is_checkout_redirect = this.props.match.params.is_checkout_redirect;
    //     console.log('is_checkout_redirect')
    //     console.log('is_checkout_redirect')
    //     console.log(is_checkout_redirect)
    //     if(is_checkout_redirect === undefined || is_checkout_redirect === 'undefined'){
    //         console.log('hello i am undefined');
    //     }
    // }
    
    render() {
        // console.log('this.state.isloading')
        // console.log(this.state.isloading)
        return (
                <Fragment>
                    <header className="container inner-header">
                        <div className="row">
                            <div className="col-12">
                                <h4>Login</h4>
                            </div>
                        </div>
                    </header>
                    <div className="inner-body addressdetails-main">
                        <div className="container my-2">
                        <Formik
                                initialValues={initialValuesSchema}
                                validationSchema={LoginSchema}
                                onSubmit={this.onSubmitForm}
                                render={({ errors, status, touched }) => (
                                    <Form>
                                        
                                        <div className="row bg-grey border-round product-bill p-0">
                                            {/* <label htmlFor="email">Email</label> */}
                                            <div className="col-12">
                                                <Field placeholder="Enter your email" name="email" type="text" className={'form-control form-control2' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Enter your password" name="password" type="password" className={'form-control form-control2' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                <ErrorMessage name="password" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        { this.state.formError ?
                                            <div className="error_label text-danger text-center mt20 mb20" role="alert">
                                                Please provide valid credentials
                                            </div>
                                        :null}
                                        {
                                            this.state.isloading ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
                                        }
                                        <div className="row border-round product-bill p-0 text-right">
                                            <div className="">
                                                <Link to="/forgot-password">Fogot Password?</Link>
                                            </div>
                                        </div>
                                        <div className="row product-action-btn mb-2 px-2">
                                            <div className="col-12">
                                                <div className="my-cart-btn">
                                                    <button type="submit" className="btn btn-primary w-100">Login</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                            
                        </div>
                    </div>
                </Fragment>
                
        
        )
    }
}


// export default Login
export default connect()(withRouter(Login));

import React from 'react';
import ComboMenuItemBlock from './ComboMenuItemBlock'; 

const LoadMoreComboItems = (props) => {
    return (
        <div>
            <div>
                <ComboMenuItemBlock
                    list={props.ComboMenuItems}
                    ComboItemPrice={props.ComboItemPrice}
                    handleCheckChieldElement={props.handleCheckChieldElement}
                    // handleCheckChieldElement={(event)=> props.handleCheckChieldElement(event)}
                />
            </div>
    
        </div>
    );
};

export default LoadMoreComboItems;
import React,{ Fragment }from 'react';
import IngredientsCombosLi from './IngredientsCombosLi';

const RenderIngredientCombos = (props) => {

    const {MenuItemsMultiple} = props;
    return(
        <Fragment>
            {MenuItemsMultiple ?
                MenuItemsMultiple.map(menuItem => (
                    <IngredientsCombosLi
                        key={menuItem._id}
                        {...menuItem}
                    />
                ))
                : null
            }
        </Fragment>
    );
        
};



export default RenderIngredientCombos

import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


export const NoInternetModal = ({showNoInternetModalModal, handleCloseShowNoInternetModalModal}) => {
    // const [modal, setModal] = useState(false);

    // const toggle = () => setModal(!modal);
    // const toggle = () => setModal(!showNoInternetModalModal);
    // const toggle = () => {
    //     handleCloseShowNoInternetModalModal(!showNoInternetModalModal)
    // };
    
    return (
        <Modal isOpen={showNoInternetModalModal} toggle={handleCloseShowNoInternetModalModal} className="NoInternetModal" >
            <ModalHeader className="text-center"  cssModule={{'modal-title': 'w-100 text-center'}} toggle={handleCloseShowNoInternetModalModal}>Internet is down.</ModalHeader>
            <ModalBody className="text-center">
               <p>Please check your internet connection.</p>
            </ModalBody>
            <ModalFooter className="text-center">
                <div className="row w-100">
                    <div className="col text-center button-group">
                        <Button color="success" className="text-center btn-block " size="lg" onClick={handleCloseShowNoInternetModalModal}>Ok</Button>
                    </div>
                    <div className="col text-center button-group">
                        <Button color="danger" className="text-center btn-block " size="lg" onClick={handleCloseShowNoInternetModalModal}>Cancel</Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { showMsg } from '../../utils/general';
import { USER_SERVER } from '../../utils/misc';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const initialValuesSchema = {
    email: '',
    security_question_answer:'',
    drpSecurityQuestion:'1',
    // drpSecurityQuestion:"0",
}
const ResetUserSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please provide a valid email')
        .required('Email is Required'),
    // drpSecurityQuestion: Yup.string()
    //     .required('Security Question is Required'),
    security_question_answer: Yup.string()
        .min(3, 'Answer must be atleast of 3 characters')
        .required('Security Question Answer is Required'),
});
class ReserUser extends Component {
    _isMounted = false;
    state = {
        isloading: false,
        formError: false,
        formSuccess:'',
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    onSubmitForm = async (fields) => {
        console.log('fields in submit')
        console.log(fields)
        if (this._isMounted) {
            this.setState({
                formError: false,
                isloading:true,
            })
        }
        axios.post(`${USER_SERVER}/reset_user`,fields)
            .then(response=>{
                this.setState({
                    isloading:false,
                })
                if(response.data.success){
                    showMsg("You can reset your password by filling the form below.",'success');
                    // this.setState({
                    //     formSuccess:true
                    // })
                    let data = response.data;
                    // const email = data.email;
                    const resetToken = data.resetToken;
                    this.props.history.push(`/reset_password/${resetToken}/true/${this.props.match.params.delivery_type}`)
                }else{
                    if(response.data.msg){
                        showMsg(response.data.msg,'error');
                    }
                }
        })
        
        
    }

    
    
    render() {
        // console.log('this.state.isloading')
        // console.log(this.state.isloading)
        return (
                <Fragment>
                    <header className="container inner-header">
                        <div className="row">
                            <div className="col-12">
                                <h4>Forgot Password</h4>
                            </div>
                        </div>
                    </header>
                    <div className="inner-body addressdetails-main">
                        <div className="container my-2">
                            <Formik
                                initialValues={initialValuesSchema}
                                validationSchema={ResetUserSchema}
                                onSubmit={this.onSubmitForm}
                                render={({ errors, status, touched }) => (
                                    <Form>
                                        
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Enter your email" name="email" type="text" className={'form-control form-control2' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        {/* <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field  component="select" name="drpSecurityQuestion" placeholder="Select Your Security Question" className={'form-control form-control2' + (errors.email && touched.email ? ' is-invalid' : '')}>
                                                    
                                                    <option value="1">Enter your mother's maiden name in below input </option>
                                                </Field>
                                                <ErrorMessage name="drpSecurityQuestion" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div> */}
                                        <div className="row bg-grey border-round product-bill p-0">
                                            <div className="col-12">
                                                <Field placeholder="Mother's maiden name." name="security_question_answer" type="text" className={'form-control form-control2' + (errors.security_question_answer && touched.security_question_answer ? ' is-invalid' : '')} />
                                                <ErrorMessage name="security_question_answer" component="div" className="invalid-feedback invalid-feedback-error pl12" />
                                            </div>
                                        </div>
                                        { this.state.formError ?
                                            <div className="error_label text-danger text-center mt20 mb20" role="alert">
                                                Please provide valid data
                                            </div>
                                        :null}
                                        {
                                            this.state.isloading ? <LoadingSpinner subClass={'mt10 mb10'} /> :null
                                        }
                                        <div className="row product-action-btn mb-2 px-2">
                                            <div className="col-12">
                                                <div className="my-cart-btn">
                                                    <button type="submit" className="btn btn-primary w-100">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />                
                        </div>
                    </div>
                </Fragment>
                
        
        )
    }
}


// export default Register
export default connect()(withRouter(ReserUser));
